import React from 'react';

import Loader from '../Loader';
import styles from './styles.module.scss';

const LoaderPage: React.FC = () => {
  return (
    <>
      <div className={styles.simpleHeaderWrapper}>
        <img alt="logo" src="blackLogo.png" />
      </div>
      <Loader />
    </>
  );
};

export default LoaderPage;

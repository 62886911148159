import * as yup from 'yup';

export enum EPayoutsKey {
  account_holder_name = 'account_holder_name',
  account_number = 'account_number',
  sort_code = 'sort_code',
  iban = 'iban',
}

export const INITIAL_VALUES = {
  [EPayoutsKey.account_holder_name]: '',
  [EPayoutsKey.account_number]: '',
  [EPayoutsKey.sort_code]: '',
  [EPayoutsKey.iban]: '',
};

export const schema = yup.object().shape({
  [EPayoutsKey.account_holder_name]: yup
    .string()
    .required('Required')
    .min(3, 'must be min 3 characters')
    .max(50, 'must be max 50 characters'),
  // [EPayoutsKey.account_number]: yup.string().required('Required'),
  // [EPayoutsKey.sort_code]: yup.string().required('Required'),
  // [EPayoutsKey.iban]: yup.string().required('Required'),
});

import { FormikProps } from 'formik';
import React, { useState } from 'react';

import { DollarIcon } from '../../../assets/icons/DollarIcon';
import { EyesIcon } from '../../../assets/icons/EyesIcon';
import { MarkIcon } from '../../../assets/icons/MarkIcon';
import Button from '../../../components/Button';
import ContentTitleBlock from '../../../components/ContentTitleBlock';
import TextButton from '../../../components/TextButton';
import { EUserKey } from '../constants';
import styles from '../styles.module.scss';
import ComparePlanePage from './ComparePlanePage';

interface IProps {
  setIsAccountPlane: (arg: boolean) => void;
  formik: FormikProps<any>;
  isSubmitLoading: boolean;
}
const ABILITIES = {
  basic: [
    'AI voice cloning',
    'AI voice to voice and text to voice conversations',
    '10,000 consecutive conversation capacity',
    'AI based support system',
  ],
  premium: [
    'The ability to send image and video content',
    '30,000 consecutive conversation capacity',
    'Human based support system',
  ],
};
const AccountPlan: React.FC<IProps> = ({ setIsAccountPlane, formik, isSubmitLoading }) => {
  const [isComparePlanePage, setIsComparePlanePage] = useState(false);

  if (isComparePlanePage) {
    return <ComparePlanePage setIsComparePlanePage={setIsComparePlanePage} />;
  }

  return (
    <div className={styles.accountPlanContainer}>
      <ContentTitleBlock
        icon={<EyesIcon />}
        title={'Which account would you be interested in?'}
        subTitle={
          'We provide two main service accounts. Basic and Premium, each designed to cater to varying needs. Payment will not be required, till after account approval'
        }
        width={911}
        subTitleMaxWidth={805}
      />
      <div className={styles.content}>
        <div className={styles.planCard}>
          <p className={styles.planCardTitle}>Basic</p>
          <p className={styles.planCardSubTitle}>
            The essentials to get your AI companion up and running
          </p>
          <div className={styles.priceBlock}>
            <div>Free</div>
            <span className={styles.planCardSubTitle}>30% commission</span>
          </div>
          <Button
            text={'Select Basic'}
            onClick={() => {
              formik.setFieldValue(EUserKey.account_type, 'basic').then(() => {
                formik.handleSubmit();
              });
            }}
            className={styles.planBtn}
            isDisable={formik.values[EUserKey.account_type] === 'pro' && isSubmitLoading}
            isLoading={formik.values[EUserKey.account_type] === 'basic' && isSubmitLoading}
          />
          <div className={styles.abilitiesBlock}>
            <p>Some of the features a Basic account offers</p>
            {ABILITIES.basic.map((text, index) => {
              return (
                <div className={styles.line} key={index}>
                  <MarkIcon /> <p>{text}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.planCard}>
          <p className={styles.planCardTitle}>Premium</p>
          <p className={styles.planCardSubTitle}>
            Offering more integrations to maximise earnings and amplify influence
          </p>
          <div className={styles.priceBlock}>
            <div>
              <DollarIcon />
              2499
            </div>
            <span className={styles.planCardSubTitle}>
              Annual fee
              <br />
              40% commission
            </span>
          </div>
          <Button
            text={'Select Premium'}
            onClick={() => {
              formik.setFieldValue(EUserKey.account_type, 'pro').then(() => {
                formik.handleSubmit();
              });
            }}
            theme={'Secondary'}
            className={styles.planBtn}
            isLoading={formik.values[EUserKey.account_type] === 'pro' && isSubmitLoading}
            isDisable={formik.values[EUserKey.account_type] === 'basic' && isSubmitLoading}
          />
          <div className={styles.abilitiesBlock}>
            <p>Everything from Basic, plus extras like:</p>
            {ABILITIES.premium.map((text, index) => {
              return (
                <div className={styles.line} key={index}>
                  <MarkIcon /> <p>{text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.simpleBtn} onClick={() => setIsComparePlanePage(true)}>
        Compare plans
      </div>
      <div className={styles.allPriceBtn}>All prices in USD</div>

      <TextButton
        text={'Back'}
        onClick={() => {
          setIsAccountPlane(false);
        }}
        theme={'Primary'}
        className={styles.txtBtn}
        isDisable={isSubmitLoading}
      />
    </div>
  );
};

export default React.memo(AccountPlan);

import React from 'react';

import AppModal from './features/AppModal/AppModal';
import Routing from './routes/router';

const App = () => {
  return (
    <>
      <AppModal />
      <Routing />
    </>
  );
};

export default App;

import getBlobDuration from 'get-blob-duration';
import React, { useEffect } from 'react';

import { WhiteLock } from '../../assets/icons/WhiteLock';
import useSecondsConverter from '../../hooks/useSecondsConverter';
import styles from './styles.module.scss';

interface IProps {
  type: string;
  url: string;
  isSummary?: boolean;
  soldCount?: number;
  isTeaser?: boolean;
}

export const MediaComponent: React.FC<IProps> = ({ url, type, isSummary, soldCount, isTeaser }) => {
  const { setSecondsAndUpdate, formattedTime } = useSecondsConverter(0);
  const width = isSummary ? '550px' : '300px';
  const height = isSummary ? '550px' : '300px';

  useEffect(() => {
    if (url && type === 'audio') {
      fetch(url)
        .then((response) => response.blob())
        .then((blobData) => {
          getBlobDuration(blobData).then(function (duration) {
            setSecondsAndUpdate(duration);
          });
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [type, url]);

  const getMediaBlock = () => {
    if (isTeaser && !url) {
      return (
        <div className={styles.lockedTeaser}>
          <button>Unlock Content</button>
          <WhiteLock />
        </div>
      );
    }

    if (type === 'image') {
      return (
        <img
          width={width}
          height={height}
          src={url}
          style={{ borderRadius: isSummary ? '20px' : '0' }}
        />
      );
    }
    if (type === 'audio') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '300px',
            width: '300px',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: isSummary ? '0px' : '80px',
            borderRadius: isSummary ? '20px' : '0',
            background: '#D9D9D9',
          }}
        >
          Audio <div style={{ width: '100%', textAlign: 'center' }}>{formattedTime}</div>
        </div>
      );
    }

    if (type === 'video') {
      return (
        <video
          controls={true}
          width={width}
          height={height}
          style={{ objectFit: 'cover', borderRadius: isSummary ? '20px' : '0' }}
          src={url}
        >
          VIDEO
        </video>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: height,
          width: width,
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: isSummary ? '0px' : '20px',
          borderRadius: isSummary ? '20px' : '0',
          background: '#D9D9D9',
        }}
      >
        File
      </div>
    );
  };
  return (
    <div style={{ position: 'relative' }}>
      {getMediaBlock()}
      {isSummary && soldCount !== undefined && (
        <div
          style={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            display: 'inlineFlex',
            padding: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            color: 'white',
            borderRadius: '5px',
            background: 'rgba(0, 0, 0, 0.50)',
            backdropFilter: 'blur(6px)',
          }}
        >
          {soldCount} sold
        </div>
      )}
    </div>
  );
};

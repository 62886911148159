import React, { useState } from 'react';

import { MirrorIcon } from '../../assets/icons/MirrorIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import { EHeader } from '../../types/header';
import PersonaForm from './PersonaForm';
import styles from './styles.module.scss';

interface IProps {
  handleHeaderType?: (arg?: EHeader) => void;
}

const FirstPersona: React.FC<IProps> = (handleHeaderType) => {
  const [isShowPersonaForm, setIsShowPersonaForm] = useState(false);
  if (isShowPersonaForm) {
    return <PersonaForm setIsShowPersonaForm={setIsShowPersonaForm} />;
  }
  return (
    <div className={styles.firstPersonaContent}>
      <ContentTitleBlock
        icon={<MirrorIcon />}
        title={'Creating your first Persona'}
        subTitle={
          "Let's kick-start your journey by creating your Persona. Your Persona will shape your AI companion's interactions, making it uniquely yours. Below is a breakdown of the necessary steps to complete your AI Persona."
        }
        subTitleMaxWidth={691}
      />
      <div className={styles.contentText}>
        <div className={styles.textBlock}>
          <p>Persona Bio</p>
          <div>
            You&apos;ll provide a brief yet captivating introduction about yourself. This is where
            you can let your personality shine by sharing your passions, interests and any other
            information you&apos;d like your AI Persona to know
          </div>
        </div>
        <div className={styles.textBlock}>
          <p>Persona Picture</p>
          <div>
            Uploading your profile picture will be the first image your followers see when
            interacting with your AI Persona. Creating an immediate sense of familiarity for your
            followers
          </div>
        </div>{' '}
        <div className={styles.textBlock}>
          <p>Persona Voice</p>
          <div>
            To bring your your AI Persona to life, we&apos;ll need a recording of your voice. This
            ensures that your AI persona&apos;s voice is a true reflection of your distinct
            identity.
          </div>
        </div>
      </div>
      <Button
        text={'Get started'}
        onClick={() => setIsShowPersonaForm(true)}
        theme={'Primary'}
        className={styles.mainBtn}
      />
    </div>
  );
};

export default React.memo(FirstPersona);

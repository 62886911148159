import React from 'react';

export const PasswordCloseEye: React.FC = () => {
  return (
    <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25 1C3.58333 3 7.35 6.5 11.75 6.5C16.15 6.5 19.9167 3 21.25 1"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M11.75 9V6.5M8.75 6L7.75 8.5M5.75 4.5L4.25 7M3.25 2.5L1.25 4.5M15.25 6L16.25 8.5M18.25 4.5L19.75 7M20.75 2.5L22.75 4.5"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
};

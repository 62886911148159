import * as yup from 'yup';

export enum EUserKey {
  email = 'email',
  full_name = 'full_name',
  date_of_birth = 'date_of_birth',
  onlyfans_url = 'onlyfans_url',
  instagram_url = 'instagram_url',
  tiktok_url = 'tiktok_url',
  youtube_url = 'youtube_url',
  snapchat_url = 'snapchat_url',
  twitch_url = 'twitch_url',
  account_type = 'account_type',
}
export const ABOUT_INPUTS = [
  { name: EUserKey.full_name, placeholder: 'Full name' },
  { name: EUserKey.date_of_birth, placeholder: 'Date of birth' },
  { name: EUserKey.onlyfans_url, placeholder: 'https://onlyfans.com' },
  { name: EUserKey.instagram_url, placeholder: 'https://instagram.com' },
  { name: EUserKey.tiktok_url, placeholder: 'https://tiktok.com' },
  { name: EUserKey.youtube_url, placeholder: 'https://youtube.com' },
  { name: EUserKey.snapchat_url, placeholder: 'https://snapchat.com' },
  { name: EUserKey.twitch_url, placeholder: 'https://twitch.com' },
];
export const INITIAL_VALUES = {
  [EUserKey.email]: '',
  [EUserKey.full_name]: '',
  [EUserKey.date_of_birth]: '',
  [EUserKey.onlyfans_url]: '',
  [EUserKey.instagram_url]: '',
  [EUserKey.tiktok_url]: '',
  [EUserKey.youtube_url]: '',
  [EUserKey.snapchat_url]: '',
  [EUserKey.twitch_url]: '',
  [EUserKey.account_type]: '',
};

export const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  full_name: yup.string().required('Required Field').min(6, 'Full name must be min 6 characters'),
  date_of_birth: yup
    .string()
    .test('custom-validation', 'Invalid Date', function (value) {
      if (!value) {
        return false;
      }
      const trimedMask = value.replaceAll('_', '').replaceAll(' ', '');
      return trimedMask.length === 8;
    })
    .required('Required Field'),
  onlyFans: yup.string(),
  instagram: yup.string().optional(),
  tiktok: yup.string().optional(),
  youtube: yup.string().optional(),
  snapchat: yup.string().optional(),
  twitch: yup.string().optional(),
  account_type: yup.string().required(),
});

import React, { useContext } from 'react';

import $api from '../../api/intercepter';
import LoaderPage from '../../components/LoaderPage.tsx';
import TextButton from '../../components/TextButton';
import MainFrame from '../../features/MainFrame';
import { useAuth } from '../../hooks/useAuth';
import { MainContext } from '../../povider/MainProvider';
import { EHeader } from '../../types/header';
import FirstPersona from './FirstPersona';
import Performance from './Performance';
import styles from './styles.module.scss';

const Persona: React.FC = () => {
  const { userData } = useContext(MainContext);
  const { isLoading } = useAuth();
  const handleDelete = () => {
    $api.delete('/wipeme').then(() => {
      window.location.reload();
    });
  };

  if (isLoading) {
    return <LoaderPage />;
  }
  return (
    <MainFrame headerType={EHeader.advanced}>
      <div className={styles.personContainer}>
        {userData?.persona?.full_name ? (
          <Performance personaData={userData?.persona} />
        ) : (
          <FirstPersona />
        )}
      </div>
      {/*<div style={{ position: 'absolute', top: '120px', right: '20px' }}>*/}
      {/*  <TextButton*/}
      {/*    text={'Delete user persona and payouts'}*/}
      {/*    onClick={handleDelete}*/}
      {/*    theme={'Secondary'}*/}
      {/*  />*/}
      {/*</div>*/}
    </MainFrame>
  );
};

export default React.memo(Persona);

import clsx from 'clsx';
import React, { useContext, useState } from 'react';

import { DiamondIcon } from '../../assets/icons/DiamondIcon';
import { LetterIcon } from '../../assets/icons/LetterIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import TextButton from '../../components/TextButton';
import { MainContext } from '../../povider/MainProvider';
import { EPremiumFormNames } from './constants';
import { MediaComponent } from './MediaComponent';
import ReadyAndWaiting from './ReadyAndWaiting';
import SendSuccess from './SendSuccess';
import styles from './styles.module.scss';

interface IProps {
  isReadyToSend?: boolean;
  data?: Record<string, string>;
  setIsSummary?: (arg: boolean) => void;
  formik?: any;
  isUploaded?: boolean;
  isLoading?: boolean;
  contentId?: number;
  fileUrl: string;
  teaserFileUrl: string;
  fileType: string;
  soldCount?: number;
}

const showedFiled = [
  EPremiumFormNames.title,
  EPremiumFormNames.price,
  EPremiumFormNames.tags,
  EPremiumFormNames.message,
];
const ContentSummary: React.FC<IProps> = ({
  isReadyToSend,
  data,
  setIsSummary,
  formik,
  isUploaded,
  isLoading,
  contentId,
  fileType,
  fileUrl,
  soldCount,
  teaserFileUrl,
}) => {
  const { setModalType } = useContext(MainContext);
  const [isChecked, setIsChecked] = useState(false);
  if (isUploaded) {
    return <ReadyAndWaiting />;
  }
  if (window.location?.search === '?isSent') {
    return <SendSuccess />;
  }
  return (
    <div className={styles.premiumContainer}>
      <ContentTitleBlock
        icon={isReadyToSend ? <LetterIcon /> : <DiamondIcon />}
        title={isReadyToSend ? 'Ready to send?' : 'Content summary'}
        subTitle={
          "Before you hit send, take a moment to confirm your content. It's your final chance to make sure everything's just right. Once you're ready, unleash your content to your audience!"
        }
        subTitleMaxWidth={837}
      />
      <div className={styles.uploadContent}>
        <div className={styles.summaryInfo}>
          <div className={styles.uploadedFilesBlock}>
            <MediaComponent isSummary type={fileType} url={fileUrl} soldCount={soldCount} />
            {(isReadyToSend || teaserFileUrl) && (
              <MediaComponent
                isSummary
                type={fileType}
                url={teaserFileUrl}
                soldCount={soldCount}
                isTeaser
              />
            )}
          </div>
          {data &&
            showedFiled.map((key) => {
              return (
                <div key={key} className={styles.summaryItem}>
                  {data[key]}
                </div>
              );
            })}
        </div>
        {!isReadyToSend && (
          <div className={styles.police}>
            <div
              className={clsx({ [styles.checked]: isChecked })}
              onClick={() => setIsChecked((prev) => !prev)}
            />

            <p>
              By selecting this box, you confirm that you are the rightful owner of the content you
              are uploading, or have obtained all necessary permissions and licenses from the
              respective copyright owners.
            </p>
          </div>
        )}
        {!isReadyToSend && !!setIsSummary && (
          <>
            <Button
              text={'Upload'}
              onClick={() => formik && formik.handleSubmit()}
              theme={'Primary'}
              className={styles.mainBtn}
              isDisable={!isChecked}
              isLoading={!!isLoading}
            />
            <TextButton
              text={'Back'}
              onClick={() => {
                setIsSummary(false);
              }}
              isDisable={isLoading}
            />
          </>
        )}
        {isReadyToSend && (
          <div className={styles.btnBlock}>
            <Button
              text={'Send'}
              onClick={() => {
                sessionStorage.setItem('sendId', String(contentId));
                setModalType('sendingContent');
              }}
              theme={'Primary'}
              className={styles.mainBtn}
            />
            <TextButton
              text={'Delete'}
              onClick={() => {
                sessionStorage.setItem('deleteId', String(contentId));
                setModalType('deletingContent');
              }}
              theme={'Negative'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ContentSummary);

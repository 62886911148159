import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { SelectYellowIcon } from '../../assets/icons/SelectYellowIcon';
import { NAVBAR_ROUTES, ROUTES } from '../../constants/routes';
import { MainContext } from '../../povider/MainProvider';
import { EHeader } from '../../types/header';
import styles from './styles.module.scss';

interface IProps {
  headerType: EHeader;
}
const NavBar: React.FC<IProps> = ({ headerType }) => {
  const { setIsAuthenticated, setUserData, userData } = useContext(MainContext);
  const [isOpenUserSettings, setIsOpenUserSettings] = useState(false);
  const handleLogout = () => {
    setIsAuthenticated(false);
    setUserData(null);
    localStorage.removeItem('token');
    navigate(ROUTES.signIn);
  };
  const handleLinkClick = (url: string) => {
    if (window.location.pathname === url) {
      // Reload the page if the clicked link is the same as the current page
      window.location.reload();
    }
  };
  const navigate = useNavigate();
  if (headerType === EHeader.simple || !userData) {
    return (
      <div className={styles.simpleHeaderWrapper}>
        <img alt="logo" src="..//blackLogo.png" />
      </div>
    );
  }

  return (
    <div className={styles.headerWrapper}>
      <img alt="logo" src="whiteLogo.png" />
      <div className={styles.navigation}>
        {NAVBAR_ROUTES.map((curr: Record<string, string>) => {
          return (
            <Link
              to={curr.url}
              className={clsx(styles.currentLink, {
                [styles.active]: curr.url === window.location.pathname,
              })}
              key={curr.title}
              onClick={() => handleLinkClick(curr.url)}
            >
              {curr.title}
            </Link>
          );
        })}
        <div
          className={clsx(styles.profile, { [styles.rotated]: isOpenUserSettings })}
          onClick={() => setIsOpenUserSettings((prev) => !prev)}
        >
          <p className={styles.userEmail}> {userData.email}</p>
          <span>
            <SelectYellowIcon />
          </span>
          {isOpenUserSettings && (
            <div className={styles.profileSettings}>
              <div className={styles.userInfo}>
                <p onClick={() => navigate(ROUTES.profile)} className={styles.settingsBtn}>
                  Settings
                </p>
              </div>
              <div className={styles.signOut} onClick={handleLogout}>
                Sign Out
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;

import { FormikProps } from 'formik';
import React, { useState } from 'react';

import { TwiGirlsIcon } from '../../assets/icons/TwiGirlsIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import TextButton from '../../components/TextButton';
import { EFormNames } from './constants';
import FormPrepare from './FormPrepare';
import styles from './styles.module.scss';

interface IProps {
  isEditing?: boolean;
  setIsShowSummary: (arg: boolean) => void;
  setIsPrepare: (arg: boolean) => void;
  formik: FormikProps<any>;
  isLoading: boolean;
  isPrepare: boolean;
}

const mockData = [
  {
    value: EFormNames.full_name,
    label: 'Full name',
  },
  {
    value: EFormNames.telegram,
    label: 'Telegram username',
  },
  {
    value: EFormNames.personality_traits,
    label: 'Personality traits',
  },
  {
    value: EFormNames.communication_style,
    label: 'Communication style',
  },
  {
    value: EFormNames.interests_and_hobbies,
    label: 'Interests and hobbies',
  },
  {
    value: EFormNames.background_and_story,
    label: 'Background and story',
  },
  {
    value: EFormNames.response_limitations,
    label: 'Response limitations',
  },
];
const PersonaSummary: React.FC<IProps> = ({
  setIsShowSummary,
  isEditing,
  formik,
  isLoading,
  isPrepare,
  setIsPrepare,
}) => {
  if (isPrepare) {
    return <FormPrepare isEditing={isEditing} />;
  }
  return (
    <div className={styles.firstPersonaContent}>
      <ContentTitleBlock
        icon={<TwiGirlsIcon />}
        title={'Persona summary'}
        subTitle={
          'Take a moment to review the details of your persona below. Once confirmed, your persona will submitted for review. When approved you’ll be ready to engage with your followers like never before.'
        }
        subTitleMaxWidth={691}
      />
      <div className={styles.summaryContent}>
        {mockData.map(({ label, value }, index) => {
          return (
            <div key={index} className={styles.contentItem}>
              <p>{label}</p>
              <div>{formik.values[value]}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.hintText}>
        Please note that your Persona will be submitted for review, this may take up to 10 days to
        be approved
      </div>
      <Button
        text={isEditing ? 'Save persona' : 'Create persona'}
        onClick={formik.handleSubmit}
        theme={'Primary'}
        className={styles.mainBtn}
        isLoading={isLoading}
      />
      <TextButton
        text={'Back'}
        onClick={() => setIsShowSummary(false)}
        className={styles.backBtn}
        isDisable={isLoading}
      />
    </div>
  );
};

export default React.memo(PersonaSummary);

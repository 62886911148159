import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import * as yup from 'yup';

import $api from '../../api/intercepter';
import { RobotIcon } from '../../assets/icons/RobotIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import LoaderPage from '../../components/LoaderPage.tsx';
import Select from '../../components/Select';
import { TSelectOptions } from '../../components/Select/type';
import TextArea from '../../components/TextArea';
import MainFrame from '../../features/MainFrame';
import { useAuth } from '../../hooks/useAuth';
import { EHeader } from '../../types/header';
import { errorHandler } from '../../utils/errorHandler';
import styles from './styles.module.scss';
import SupportSuccess from './SupportSuccess';

const SELECT_OPTIONS: TSelectOptions = [
  {
    value: 'general',
    label: 'General enquiry',
  },
  {
    value: 'press',
    label: 'Press',
  },
  {
    value: 'account',
    label: 'Account upgrade',
  },
  {
    value: 'technical',
    label: 'Technical support',
  },
];
const INITIAL_VALUES = {
  text: '',
  subject: '',
};

const schema = yup.object().shape({
  text: yup.string().required('Required'),
  subject: yup.string().required(),
});

const Support: React.FC = () => {
  const [isSent, setIsSent] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const { isLoading } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: schema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      setIsRequestLoading(true);
      $api
        .post('/support', {
          subject: values.subject,
          message: values.text,
        })
        .then(() => {
          setIsSent(true);
        })
        .catch((error) => {
          errorHandler(error, enqueueSnackbar, formik);
        })
        .finally(() => {
          setIsRequestLoading(false);
        });
    },
  });
  if (isLoading) {
    return <LoaderPage />;
  }

  const handleBack = () => {
    formik.resetForm();
    setIsSent(false);
  };
  return (
    <MainFrame headerType={EHeader.advanced}>
      <div className={styles.supportContainer}>
        {isSent ? (
          <SupportSuccess handleBack={handleBack} />
        ) : (
          <>
            <ContentTitleBlock
              icon={<RobotIcon />}
              title={'Need some help?'}
              subTitle={
                "We’re here to address any queries you may have throughout your AI companion journey. Whether you need assistance with the dashboard, your persona or account upgrades, we've got you covered"
              }
              subTitleMaxWidth={639}
            />
            <div className={styles.content}>
              <Select
                name={'subject'}
                options={SELECT_OPTIONS}
                handleChange={formik.setFieldValue}
                placeholder={'Subject'}
              />
              <TextArea
                value={formik.values.text}
                name={'text'}
                handleChange={formik.setFieldValue}
                height={418}
                placeholder={'Message'}
              />
            </div>
            <Button
              text={'Continue'}
              onClick={formik.handleSubmit}
              isDisable={!formik.values.text || !formik.values.subject}
              isLoading={isRequestLoading}
            />
          </>
        )}
      </div>
    </MainFrame>
  );
};

export default React.memo(Support);

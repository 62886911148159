import clsx from 'clsx';
import React from 'react';

import NavBar from '../../components/NavBar';
import { useHeader } from '../../hooks/useHeader';
import { EHeader } from '../../types/header';
import styles from './styles.module.scss';

interface IMainFrame {
  headerType?: EHeader;
  children: any;
}

const MainFrame: React.FC<IMainFrame> = ({ children, headerType: initialHeader }) => {
  const { headerType, handleHeaderType } = useHeader(initialHeader);
  return (
    <div className={clsx(styles.mainFrame, { [styles.withHeader]: !!headerType })}>
      {!!headerType && <NavBar headerType={headerType} />}
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, { handleHeaderType });
      })}
    </div>
  );
};

export default MainFrame;

import React from 'react';

import { YellowMarkIcon } from '../../../assets/icons/YellowMarkIcon';
import styles from '../styles.module.scss';

const COMPARE_DATA = [
  { text: 'A weekly paid cut of revenue generated', basic: '30%', premium: '40%' },
  { text: 'AI training based on 30 well-engineered prompts', basic: '', premium: '' },
  { text: 'AI voice cloning', basic: '', premium: '' },
  {
    text: 'AI voice to voice messaging and text to voice conversations',
    basic: '',
    premium: '',
  },
  {
    text: 'An analytics platform to track earnings and user engagement',
    basic: '',
    premium: '',
  },
  { text: 'Send image and video content', basic: 'Not included', premium: 'Included' },
  { text: 'An exclusive Telegram channel', basic: '', premium: '' },
  { text: 'Consecutive conversations capacity', basic: '10,000', premium: '30,000' },
  { text: 'A copy of the Telegram client database', basic: '', premium: '' },
  { text: 'Support system', basic: 'AI based', premium: 'Human based' },
];

interface IProps {
  setIsComparePlanePage: (arg: boolean) => void;
}
const ComparePlanePage: React.FC<IProps> = ({ setIsComparePlanePage }) => {
  return (
    <div className={styles.comparePlaneContainer}>
      <div className={styles.tableColumn}>
        <div className={styles.tableHeadColumnLeft}>Features</div>
        <div className={styles.tableHeadColumnRight}>
          <div>Basic</div>
          <div>Premium</div>
        </div>
      </div>
      {COMPARE_DATA.map(({ text, basic, premium }, index) => {
        return (
          <div key={index} className={styles.tableColumn}>
            <div className={styles.tableColumnLeft}>{text}</div>
            <div className={styles.tableColumnRight}>
              <div>{basic || <YellowMarkIcon />}</div>
              <div>{premium || <YellowMarkIcon />}</div>
            </div>
          </div>
        );
      })}
      <div
        className={styles.simpleBtn}
        style={{ margin: '105px 205px' }}
        onClick={() => setIsComparePlanePage(false)}
      >
        Back
      </div>
    </div>
  );
};
export default React.memo(ComparePlanePage);

import { ReactNode } from 'react';

import DeleteModal from './components/DeleteModal';
import PremiumModal from './components/PremiumModal';
import SendModal from './components/SendModal';

export type TModal = 'premium' | 'sendingContent' | 'deletingContent';
export const MODALS: Record<TModal, ReactNode> = {
  premium: <PremiumModal />,
  sendingContent: <SendModal />,
  deletingContent: <DeleteModal />,
};

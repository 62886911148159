import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import * as Yup from 'yup';

import $api from '../../api/intercepter';
import { KeyIcon } from '../../assets/icons/KeyIcon';
import { LikeIcon } from '../../assets/icons/LikeIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import Input from '../../components/Input';
import TextButton from '../../components/TextButton';
import { errorHandler } from '../../utils/errorHandler';
import styles from './styles.module.scss';

interface IProps {
  setIsChangePassword: (arg: boolean) => void;
}
const INITIAL_VALUES = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};

const schema = Yup.object().shape({
  old_password: Yup.string().required('Required').min(10, 'Password must be min 10 characters'),
  new_password: Yup.string()
    .min(10, 'Password must be min 10 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\\$%\\^&\\*])(?=.{8,})/,
      'Password must contain at least 8 characters, including one uppercase, one lowercase, one digit or special character'
    )
    .required('Required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), ''], 'Passwords must match') // Changed null to an empty string

    .required('Required'),
});
const ChangePassword: React.FC<IProps> = ({ setIsChangePassword }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      $api
        .post('/password/setnewpassword', {
          old_password: values.old_password,
          new_password: values.new_password,
          confirm_password: values.confirm_password,
        })
        .then((response) => {
          if (response.data.result[0]) {
            setIsSuccess(true);
            formik.resetForm();
          } else {
            formik.setFieldError('old_password', 'Current password not correct');
          }
        })
        .catch((error) => {
          errorHandler(error, enqueueSnackbar, formik);
        });
    },
  });

  return (
    <div className={styles.changePassContainer}>
      {isSuccess ? (
        <>
          <ContentTitleBlock
            icon={<LikeIcon />}
            title={'Your all set'}
            subTitle={
              'Your password has been changed successfully. Your account security is our priority'
            }
            subTitleMaxWidth={639}
          />
          <Button
            text={'Back to profile'}
            onClick={() => setIsChangePassword(false)}
            className={styles.successBackBtn}
          />
        </>
      ) : (
        <>
          <ContentTitleBlock
            icon={<KeyIcon />}
            title={'Change your password'}
            subTitle={
              'Remember to keep your password safe and do not share it with anyone. If you encounter any issues or need assistance, please contact our support team. Your security is our priority.'
            }
            subTitleMaxWidth={841}
          />
          <div className={styles.content}>
            <Input
              name={'old_password'}
              placeholder={'Old password'}
              type={'password'}
              error={formik.errors.old_password as string}
              value={formik.values.old_password}
              handleChange={formik.setFieldValue}
              onKeyDown={formik.handleSubmit}
            />
            <Input
              name={'new_password'}
              placeholder={'New password'}
              type={'password'}
              error={formik.errors.new_password as string}
              value={formik.values.new_password}
              handleChange={formik.setFieldValue}
              onKeyDown={formik.handleSubmit}
            />
            <Input
              name={'confirm_password'}
              placeholder={'Confirm new password'}
              type={'password'}
              error={formik.errors.confirm_password as string}
              value={formik.values.confirm_password}
              handleChange={formik.setFieldValue}
              onKeyDown={formik.handleSubmit}
            />
            <Button
              text={'Continue'}
              onClick={formik.handleSubmit}
              className={styles.mainBtn}
              isDisable={
                !formik.values.confirm_password ||
                !formik.values.new_password ||
                !formik.values.old_password
              }
            />
            <TextButton
              text={'Back'}
              onClick={() => {
                setIsChangePassword(false);
              }}
              className={styles.txtBtn}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(ChangePassword);

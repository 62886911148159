import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import $api from '../../api/intercepter';
import { DiamondIcon } from '../../assets/icons/DiamondIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import { EPremiumFormNames, IPremium } from './constants';
import ContentSummary from './ContentSummary';
import { MediaComponent } from './MediaComponent';
import styles from './styles.module.scss';
import UploadContent from './UploadContent';

const FILTER: Record<string, string> = {
  Photo: 'image',
  Video: 'video',
  Audio: 'audio',
};

const PremiumWelcome: React.FC = () => {
  const [isUploadForm, setIsUploadForm] = useState(false);
  const [filterType, setFilterType] = useState('All');
  const [currentData, setCurrentData] = useState<IPremium | null>(null);
  const [premiumData, setPremiumData] = useState<Array<IPremium>>([]);
  const [filteredPremiumData, setFilteredPremiumData] = useState<Array<IPremium>>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    $api
      .get('/premiumcontent')
      .then((resp) => {
        if (resp.data.result[0] !== false) {
          setPremiumData(resp.data.result[0]);
        }
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      });
  }, []);

  useEffect(() => {
    if (premiumData) {
      if (filterType === 'All') {
        setFilteredPremiumData(premiumData);
      } else {
        setFilteredPremiumData(premiumData.filter((item) => item.category === FILTER[filterType]));
      }
    }
  }, [filterType, premiumData]);
  if (isUploadForm) {
    return <UploadContent handleBack={() => setIsUploadForm(false)} />;
  }
  if (currentData) {
    return (
      <>
        <ContentSummary
          isReadyToSend={true}
          data={{
            [EPremiumFormNames.title]: currentData[EPremiumFormNames.title],
            [EPremiumFormNames.price]: currentData[EPremiumFormNames.price],
            [EPremiumFormNames.tags]: currentData[EPremiumFormNames.tags],
            [EPremiumFormNames.message]: currentData[EPremiumFormNames.message],
          }}
          contentId={currentData.id}
          fileType={currentData.category}
          fileUrl={currentData.file_url}
          teaserFileUrl={currentData.file_teaser_url}
          soldCount={currentData?.number_of_downloads}
        />
      </>
    );
  }
  return (
    <div className={styles.premiumContainer}>
      <ContentTitleBlock
        icon={<DiamondIcon />}
        title={'The premium experience'}
        subTitle={
          'Seamlessly send content to your audience interacting with your AI companion, with the option to purchase via Telegram. Seduce, entice and share intimate moments to maximise your earnings'
        }
        subTitleMaxWidth={810}
      />
      {premiumData.length ? (
        <div className={styles.dataContent}>
          <div className={styles.contentHeader}>
            <div className={styles.contentFilter}>
              {['All', ...Object.keys(FILTER)].map((item) => {
                return (
                  <div
                    key={item}
                    onClick={() => setFilterType(item)}
                    className={clsx({ [styles.active]: filterType === item })}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
            <Button
              text={'Upload content'}
              onClick={() => setIsUploadForm(true)}
              theme={'Primary'}
              className={styles.mainBtn}
            />
          </div>
          <div className={styles.cardContent}>
            {filteredPremiumData.length ? (
              filteredPremiumData.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setCurrentData(item);
                    }}
                  >
                    <MediaComponent type={item.category} url={item.file_url} />
                    <span>{item.number_of_downloads} sold</span>
                  </div>
                );
              })
            ) : (
              <p style={{ textAlign: 'center', width: '100%' }}>Not data</p>
            )}
          </div>
        </div>
      ) : (
        <Button
          text={'Upload content'}
          onClick={() => setIsUploadForm(true)}
          theme={'Primary'}
          className={styles.mainBtn}
        />
      )}
    </div>
  );
};

export default React.memo(PremiumWelcome);

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { HandSuccess } from '../../../assets/icons/HandSuccess';
import ContentTitleBlock from '../../../components/ContentTitleBlock';
import TextButton from '../../../components/TextButton';
import { ROUTES } from '../../../constants/routes';
import styles from '../styles.module.scss';

const SuccessPart: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.emailPartContainer}>
      <ContentTitleBlock
        icon={<HandSuccess />}
        title={'Thank you for your request'}
        subTitle={
          "Please allow us a short time to process your request and review your details. Once your account is approved, you'll receive an email notification with further instructions on how to proceed"
        }
        width={713}
      />
      <TextButton
        text={'Back to Sign in'}
        onClick={() => {
          navigate(ROUTES.signIn);
        }}
        theme={'Secondary'}
        className={styles.txtBtn}
      />
    </div>
  );
};

export default React.memo(SuccessPart);

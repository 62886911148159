import './index.css';

import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import MainProvider from './povider/MainProvider';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MainProvider>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: 80 }}
        autoHideDuration={5000}
        hideIconVariant
        disableWindowBlurListener
      >
        <App />
      </SnackbarProvider>
    </MainProvider>
  </React.StrictMode>
);
reportWebVitals();

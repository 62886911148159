import { useEffect, useState } from 'react';

function useSecondsConverter(totalSeconds: number) {
  const [seconds, setSeconds] = useState(totalSeconds);
  const [formattedTime, setFormattedTime] = useState('00:00:00');

  useEffect(() => {
    const updateFormattedTime = () => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      const formatted = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}:${String(remainingSeconds).padStart(2, '0')}`;
      setFormattedTime(formatted);
    };

    updateFormattedTime();
  }, [seconds]);

  const setSecondsAndUpdate = (newSeconds: number) => {
    setSeconds(newSeconds);
  };

  return {
    seconds,
    formattedTime,
    setSecondsAndUpdate,
  };
}

export default useSecondsConverter;

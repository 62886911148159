export const ROUTES = {
  home: '/',
  signIn: '/sign-in',
  forgotPassword: '/forgot-password',
  payouts: '/payouts',
  persona: '/persona',
  premium: '/premium',
  referrals: '/referrals',
  support: '/support',
  profile: '/profile',
  reset: '/password/reset',
  'referral-link': '/referral-link',
};
export const NAVBAR_ROUTES = [
  {
    url: '/persona',
    title: 'Persona',
  },
  {
    url: '/premium',
    title: 'Premium',
  },
  {
    url: '/referrals',
    title: 'Referrals',
  },
  {
    url: '/payouts',
    title: 'Payouts',
  },
  {
    url: '/support',
    title: 'Support',
  },
];

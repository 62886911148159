export const RemoveIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_881_6553)">
        <rect width="60" height="60" rx="5" fill="white" fillOpacity="0.5" />
      </g>
      <path
        d="M21.5143 38.4853C21.2606 38.2316 21.1338 37.9265 21.1338 37.5699C21.1338 37.1996 21.2572 36.8911 21.5041 36.6442L28.1586 29.9897L21.5143 23.3455C21.2675 23.0986 21.1441 22.7969 21.1441 22.4404C21.1372 22.077 21.2606 21.7684 21.5143 21.5147C21.768 21.261 22.0766 21.1376 22.44 21.1445C22.8034 21.1376 23.1085 21.2576 23.3554 21.5044L29.9996 28.1487L36.6439 21.5044C36.8907 21.2576 37.1958 21.1376 37.5592 21.1445C37.9226 21.1376 38.2312 21.261 38.4849 21.5147C38.7386 21.7684 38.862 22.077 38.8552 22.4404C38.8552 22.7969 38.7317 23.0986 38.4849 23.3455L31.8407 29.9897L38.4952 36.6442C38.742 36.8911 38.862 37.1962 38.8552 37.5596C38.862 37.923 38.7386 38.2316 38.4849 38.4853C38.2312 38.739 37.9226 38.8624 37.5592 38.8555C37.2027 38.8555 36.901 38.7321 36.6541 38.4853L29.9996 31.8308L23.3451 38.4853C23.0983 38.7321 22.7931 38.859 22.4297 38.8658C22.0732 38.8658 21.768 38.739 21.5143 38.4853Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_b_881_6553"
          x="-12"
          y="-12"
          width="84"
          height="84"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_881_6553" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_881_6553"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

import React from 'react';

export const WhiteLock: React.FC = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1465_4513)">
        <circle cx="32" cy="30" r="26" fill="white" />
        <path
          d="M25.2188 44C24.1458 44 23.3412 43.724 22.8047 43.1719C22.2682 42.6293 22 41.7726 22 40.6017V30.7639C22 29.6121 22.2682 28.7649 22.8047 28.2223C23.3412 27.6702 24.1458 27.3942 25.2188 27.3942H38.3812C39.4542 27.3942 40.2588 27.6702 40.7953 28.2223C41.3318 28.7649 41.6 29.6121 41.6 30.7639V40.6017C41.6 41.7726 41.3318 42.6293 40.7953 43.1719C40.2588 43.724 39.4542 44 38.3812 44H25.2188ZM25.305 41.8582H38.3094C38.6447 41.8582 38.889 41.7726 39.0422 41.6012C39.2051 41.4394 39.2865 41.1586 39.2865 40.7588V30.6211C39.2865 30.2213 39.2051 29.9405 39.0422 29.7787C38.889 29.6169 38.6447 29.536 38.3094 29.536H25.305C24.9697 29.536 24.7206 29.6169 24.5578 29.7787C24.3949 29.9405 24.3135 30.2213 24.3135 30.6211V40.7588C24.3135 41.1586 24.3949 41.4394 24.5578 41.6012C24.7206 41.7726 24.9697 41.8582 25.305 41.8582ZM24.4859 28.5222V23.9816C24.4859 22.6395 24.6871 21.4686 25.0894 20.4691C25.5014 19.4697 26.0522 18.6415 26.7419 17.9847C27.4317 17.3184 28.2124 16.8234 29.0842 16.4997C29.9559 16.1666 30.8612 16 31.8 16C32.7388 16 33.6441 16.1666 34.5158 16.4997C35.3972 16.8234 36.1827 17.3184 36.8724 17.9847C37.5622 18.6415 38.1082 19.4697 38.5106 20.4691C38.9129 21.4686 39.1141 22.6395 39.1141 23.9816V28.5222H36.8006V23.6818C36.8006 22.4824 36.5707 21.4782 36.1109 20.669C35.651 19.8504 35.0427 19.2364 34.2859 18.8271C33.5291 18.4083 32.7005 18.1989 31.8 18.1989C30.9091 18.1989 30.0805 18.4083 29.3141 18.8271C28.5573 19.2364 27.949 19.8504 27.4891 20.669C27.0293 21.4782 26.7994 22.4824 26.7994 23.6818V28.5222H24.4859Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1465_4513"
          x="0"
          y="0"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1465_4513" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1465_4513"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

import React, { useContext, useState } from 'react';

import $api from '../../api/intercepter';
import { MoneyBag } from '../../assets/icons/MoneyBag';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import TextButton from '../../components/TextButton';
import { MainContext } from '../../povider/MainProvider';
import { EHeader } from '../../types/header';
import BankCard from './BankCard';
import PayoutView from './PayoutView';
import styles from './styles.module.scss';

interface IProps {
  handleHeaderType?: (arg?: EHeader) => void;
}

const PayoutComponent: React.FC<IProps> = ({ handleHeaderType }) => {
  const [isBankCardStep, setIsBankCartStep] = useState(false);
  const { userData } = useContext(MainContext);

  const handleDelete = () => {
    $api.delete('/wipeme').then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <div className={styles.payoutsContainer}>
        {userData?.payout_method ? (
          <PayoutView payoutData={userData.payout_method} handleHeaderType={handleHeaderType} />
        ) : isBankCardStep ? (
          <BankCard handleHeaderType={handleHeaderType} />
        ) : (
          <>
            <ContentTitleBlock
              icon={<MoneyBag />}
              title={'Seamless payouts'}
              subTitle={
                'We believe in providing you with a seamless and hassle-free earnings experience.'
              }
              subTitleMaxWidth={486}
            />
            <Button
              text={'Add payout'}
              onClick={() => setIsBankCartStep(true)}
              className={styles.addPayoutBtn}
            />
          </>
        )}
      </div>
      {/*<div style={{ position: 'absolute', top: '120px', right: '20px' }}>*/}
      {/*  <TextButton*/}
      {/*    text={'Delete user persona and payouts'}*/}
      {/*    onClick={handleDelete}*/}
      {/*    theme={'Secondary'}*/}
      {/*  />*/}
      {/*</div>*/}
    </>
  );
};

export default React.memo(PayoutComponent);

import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import $api from '../../api/intercepter';
import { MuscleIcon } from '../../assets/icons/MuscleIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import TextButton from '../../components/TextButton';
import { MainContext } from '../../povider/MainProvider';
import { IPersona } from '../../types/user';
import PersonaForm from './PersonaForm';
import styles from './styles.module.scss';

interface IProps {
  personaData: IPersona;
}
export interface IRevenueData {
  persona_revenue?: string;
  referral_revenue?: string;
  total_sessions?: number;
  active_commission?: string;
  total_revenue?: string;
}

const Performance: React.FC<IProps> = ({ personaData }) => {
  const [period, setPeriod] = useState<'week' | 'month'>('week');
  const [isEdit, setIsEdit] = useState(false);
  const [revenueData, setRevenueData] = useState<IRevenueData>({});
  const { userData } = useContext(MainContext);
  const isPending = !userData?.persona?.approved;

  useEffect(() => {
    $api.get(`revenue?period=${period}`).then((resp) => {
      setRevenueData(resp.data.result[0]);
    });
  }, [period]);

  if (isEdit) {
    return (
      <PersonaForm isEditing={isEdit} setIsShowPersonaForm={setIsEdit} personaData={personaData} />
    );
  }

  return (
    <div className={styles.firstPersonaContent}>
      <ContentTitleBlock
        icon={<MuscleIcon />}
        title={'Your persona and performance'}
        subTitle={
          "Welcome to your Persona and Performance Hub. Here, you can fine-tune your active persona, tailoring its communication style, personality traits, and unique expressions to captivate your audience. While offering real-time performance metrics of your persona's earnings and engagement rates, empowering you to refine your strategy for maximum influence and income"
        }
        subTitleMaxWidth={966}
      />
      <div className={styles.performanceContent}>
        <div className={styles.personaBlock}>
          <div className={styles.textBlock}>
            <p>Persona (Version 1.0)</p>
            <span>Last updated 25th Aug, 2023</span>
          </div>
          <div className={styles.btnBlock}>
            {isPending && (
              <p className={styles.hiddenText}>
                We&apos;re currently processing your Persona, this may take up to 10 days to
                complete.
              </p>
            )}
            <Button
              text={isPending ? 'Pending' : 'Active'}
              theme={isPending ? 'Secondary' : 'Active'}
              className={styles.pendingBtn}
            />
            {!isPending && <TextButton text={'Edit'} onClick={() => setIsEdit(true)} />}
          </div>
        </div>
        <div className={styles.periodBlock}>
          <div
            className={clsx({ [styles.activePeriod]: period === 'week' })}
            onClick={() => setPeriod('week')}
          >
            Week
          </div>
          <div
            className={clsx({ [styles.activePeriod]: period === 'month' })}
            onClick={() => setPeriod('month')}
          >
            Month
          </div>
        </div>
        <div className={styles.totalRevenue}>
          <p>${revenueData.total_revenue}</p>
          <div>Total revenue</div>
        </div>
        <div className={styles.revenuesBlock}>
          <div>
            <p>${revenueData.persona_revenue}</p>
            <span>Persona revenue</span>
          </div>
          <div>
            <p>${revenueData.referral_revenue}</p>
            <span>Referral revenue</span>
          </div>{' '}
          <div>
            <p>{revenueData.total_sessions}</p>
            <span>Total sessions</span>
          </div>
          <div>
            <p>{revenueData.active_commission}%</p>
            <span>Active commission</span>
          </div>
        </div>
      </div>

      <Button
        text={'Download full CSV report'}
        onClick={() => {
          $api.get('premiumcontent/analytics').then((response) => {
            console.log('response', response);
            const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
            const link = document.createElement('a');
            link.setAttribute('href', csvContent);
            link.setAttribute('download', 'example.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }}
        theme={'Primary'}
        className={styles.mainBtn}
      />
      <div className={styles.performanceText}>
        Your CSV file is a structured data file that contains key metrics and data about how your AI
        companion is performing. Providing a clear, organised snapshot of your AI companion&apos;s
        impact and allows for easy analysis and tracking of its performance trends over time.
      </div>
    </div>
  );
};

export default React.memo(Performance);

import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import { HandIcon } from '../../../assets/icons/HandIcon';
import Button from '../../../components/Button';
import ContentTitleBlock from '../../../components/ContentTitleBlock';
import Input from '../../../components/Input';
import TextButton from '../../../components/TextButton';
import { EHeader } from '../../../types/header';
import { ABOUT_INPUTS, EUserKey } from '../constants';
import styles from '../styles.module.scss';
import AccountPlan from './AccountPlan';

interface IProps {
  setIsEmailPart: (arg: boolean) => void;
  setIsAccountPlane: (arg: boolean) => void;
  handleHeaderType?: (arg?: EHeader) => void;
  formik: FormikProps<any>;
  isSubmitLoading: boolean;
  isAccountPlane: boolean;
}

const AboutPart: React.FC<IProps> = ({
  handleHeaderType,
  setIsEmailPart,
  formik,
  isSubmitLoading,
  isAccountPlane,
  setIsAccountPlane,
}) => {
  const [readyToRedirection, setReadyToRedirection] = useState(false);

  useEffect(() => {
    if (readyToRedirection) {
      if (!formik.errors.full_name && !formik.errors.date_of_birth) {
        setIsAccountPlane(true);
      }
      setReadyToRedirection(false);
    }
  }, [readyToRedirection, formik.errors]);

  if (isAccountPlane) {
    return (
      <AccountPlan
        isSubmitLoading={isSubmitLoading}
        formik={formik}
        setIsAccountPlane={setIsAccountPlane}
      />
    );
  }

  return (
    <div className={styles.aboutPartContainer}>
      <ContentTitleBlock
        icon={<HandIcon />}
        title={'A little bit about you'}
        subTitle={
          'Fill out as much as you can below, so we can get a better idea about you, to help approve your account request'
        }
        width={545}
      />
      <div className={styles.content}>
        {ABOUT_INPUTS.map(({ name, placeholder }) => {
          return (
            <Input
              key={name}
              error={formik.errors[name] as string}
              name={name}
              value={formik.values[name]}
              placeholder={placeholder}
              handleChange={formik.setFieldValue}
              withMask={name === EUserKey.date_of_birth}
            />
          );
        })}

        <Button
          text={'Continue'}
          onClick={() => {
            Promise.all([
              formik.validateField(EUserKey.full_name),
              formik.validateField(EUserKey.date_of_birth),
            ]).then(() => {
              setReadyToRedirection(true);
            });
          }}
          className={styles.mainBtn}
        />
        <TextButton
          text={'Back'}
          onClick={() => {
            formik.setFieldValue(EUserKey.full_name, '');
            formik.setFieldValue(EUserKey.date_of_birth, '');
            if (handleHeaderType) {
              handleHeaderType(undefined);
            }
            setIsEmailPart(true);
          }}
          theme={'Primary'}
          className={styles.txtBtn}
        />
      </div>
    </div>
  );
};

export default React.memo(AboutPart);

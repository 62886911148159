import * as yup from 'yup';

export enum EPremiumFormNames {
  title = 'title',
  price = 'price',
  tags = 'tags',
  message = 'message',
}

export const INITIAL_VALUES = {
  [EPremiumFormNames.title]: '',
  [EPremiumFormNames.price]: '',
  [EPremiumFormNames.tags]: '',
  [EPremiumFormNames.message]: '',
};

export const schema = yup.object().shape({
  [EPremiumFormNames.title]: yup
    .string()
    .required('Required')
    .min(3, 'must be min 3 characters')
    .max(50, 'must be max 50 characters'),
  [EPremiumFormNames.price]: yup.string().required('Required'),
  [EPremiumFormNames.tags]: yup
    .string()
    .required('Required')
    .min(3, 'must be min 3 characters')
    .max(50, 'must be max 50 characters'),
  [EPremiumFormNames.message]: yup.string().required('Required'),
});
export interface IPremium {
  id: number;
  title: string;
  price: string;
  tags: string;
  message: string;
  category: string;
  filename: string;
  file_url: string;
  file_teaser_url: string;
  number_of_downloads: number;
  created_at: string;
}

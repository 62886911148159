import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import $api from '../api/intercepter';
import { ROUTES } from '../constants/routes';
import { MainContext } from '../povider/MainProvider';

export const useAuth = (isPublicRoute?: boolean) => {
  const { setIsAuthenticated, setUserData, isAuthenticated } = useContext(MainContext);
  const [isLoading, setIsLoading] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ROUTES['referral-link']) {
      const referralCode = location?.search.slice(1);
      localStorage.removeItem('token');
      setReferralCode(referralCode);
      setIsLoading(false);
    }

    const token = localStorage.getItem('token');
    if (token) {
      if (!isAuthenticated) {
        setIsLoading(true);
        $api
          .get('/whoami')
          .then(({ data }) => {
            setIsAuthenticated(true);
            setUserData(data.result[0]);
            setIsLoading(false);
            if (isPublicRoute) {
              navigate(ROUTES.persona);
            }
          })
          .catch(() => {
            !isPublicRoute && navigate(ROUTES.signIn);
            localStorage.removeItem('token');
            setIsLoading(false);
          });
      }
    } else {
      !isPublicRoute && navigate(ROUTES.signIn);
    }
  }, []);

  return { isLoading, referralCode };
};

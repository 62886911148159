import React, { useMemo } from 'react';

import { CameraIcon } from '../../assets/icons/CameraIcon';
import { EditIcon } from '../../assets/icons/EditIcon';
import { HandIcon } from '../../assets/icons/HandIcon';
import { MicrophoneIcon } from '../../assets/icons/MicrophoneIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import FileInput from '../../components/FileInput';
import Input from '../../components/Input';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';
import TextButton from '../../components/TextButton';
import { usePersona } from '../../hooks/usePersona';
import { IPersona } from '../../types/user';
import { getCurrentSelectItem } from '../../utils/getCurrentSelectItem';
import { BODY_TYPE, DRESS_SIZE, EFormNames, SEXUAL_PREFERENCE } from './constants';
import PersonaSummary from './PersonaSummary';
import styles from './styles.module.scss';

interface IProps {
  isEditing?: boolean;
  setIsShowPersonaForm: (arg: boolean) => void;
  personaData?: IPersona;
}
export enum EStep {
  form = 'form',
  image = 'image',
  audio = 'audio',
}

const PersonaForm: React.FC<IProps> = ({ isEditing, setIsShowPersonaForm, personaData }) => {
  const {
    step,
    uploadedFileImage,
    isShowSummary,
    isLoading,
    formik,
    uploadedFileSrcImage,
    uploadedFileVoice,
    isPrepare,
    uploadedFileSrcVoice,
    setIsShowSummary,
    setIsPrepare,
    setUploadedFileSrcVoice,
    handleNextClick,
    setStep,
    setUploadedFileImage,
    setUploadedFileSrcImage,
    setUploadedFileVoice,
  } = usePersona(isEditing, personaData);
  const contentData = useMemo(() => {
    if (step === EStep.image) {
      return {
        icon: <CameraIcon />,
        title: 'Persona picture',
        subTitle:
          'This will be the first image your audience see’s when interacting with your persona. Let your image speak volumes about the intimate journey your audience are about to experience, leaving them eager to connect with your AI companion.',
        subTitleMaxWidth: 745,
      };
    }
    if (step === EStep.audio) {
      return {
        icon: <MicrophoneIcon />,
        title: 'Persona voice',
        subTitle:
          "To bring your your Persona to life, we'll need just one audio file from you. Please keep in mind that the audio file must be as close to, but not exceed 1 hour in duration or 50MB in size. This ensures that your AI persona's voice is a true reflection of your distinct identity",
        subTitleMaxWidth: 792,
      };
    }
    return {
      icon: isEditing ? <EditIcon /> : <HandIcon />,
      title: isEditing ? 'Edit Persona' : 'Persona bio',
      subTitle: isEditing
        ? "When editing your persona, please note that it may take up to 10 days for these changes to fully take effect. So, don't be alarmed if you don't see immediate results. Your updated persona will be up and running, captivating your audience in no time"
        : 'Tailor your Persona to perfection by choosing your preferred communication style and personality traits. This step ensures that the AI companion truly embodies your unique identity and brand',
      subTitleMaxWidth: 745,
    };
  }, [step]);

  const formContent = useMemo(() => {
    if (step === EStep.image) {
      return (
        <FileInput
          uploadedFile={uploadedFileImage}
          setUploadedFile={setUploadedFileImage}
          uploadedFileSrc={uploadedFileSrcImage}
          setUploadedFileSrc={setUploadedFileSrcImage}
          setFormValue={formik.setFieldValue}
          type={'image'}
        />
      );
    }
    if (step === EStep.audio) {
      return (
        <FileInput
          uploadedFile={uploadedFileVoice}
          setUploadedFile={setUploadedFileVoice}
          uploadedFileSrc={uploadedFileSrcVoice}
          setUploadedFileSrc={setUploadedFileSrcVoice}
          type={'audio'}
          setFormValue={formik.setFieldValue}
        />
      );
    }

    return (
      <>
        <Input
          name={EFormNames.full_name}
          placeholder={'Full name'}
          handleChange={formik.setFieldValue}
          value={formik.values[EFormNames.full_name]}
          error={formik.errors[EFormNames.full_name]}
        />
        <div className={styles.telegramBlock}>
          <Input
            name={EFormNames.telegram}
            placeholder={'@  Telegram username'}
            handleChange={formik.setFieldValue}
            value={formik.values[EFormNames.telegram]}
            error={formik.errors[EFormNames.telegram]}
            postFix={'bot'}
          />
        </div>
        <div className={styles.formLine}>
          <Input
            name={EFormNames.age}
            placeholder={'Age'}
            width={76}
            handleChange={formik.setFieldValue}
            value={formik.values[EFormNames.age]}
            error={formik.errors[EFormNames.age]}
            type={'number'}
          />
          <Input
            name={EFormNames.height}
            type={'number'}
            placeholder={'Height'}
            postFix={'cm'}
            width={154}
            handleChange={formik.setFieldValue}
            value={formik.values[EFormNames.height]}
            error={formik.errors[EFormNames.height]}
          />
          <Select
            selectedItem={
              formik.values[EFormNames.body_type]
                ? getCurrentSelectItem(formik.values[EFormNames.body_type], BODY_TYPE)
                : undefined
            }
            name={EFormNames.body_type}
            options={BODY_TYPE}
            handleChange={formik.setFieldValue}
            placeholder={'Body type'}
            width={170}
            error={formik.errors[EFormNames.body_type]}
          />
          <Input
            name={EFormNames.ethnicity}
            placeholder={'Ethnicity'}
            handleChange={formik.setFieldValue}
            value={formik.values[EFormNames.ethnicity]}
            error={formik.errors[EFormNames.ethnicity]}
          />
          <Input
            name={EFormNames.residency}
            placeholder={'Residency'}
            handleChange={formik.setFieldValue}
            value={formik.values[EFormNames.residency]}
            error={formik.errors[EFormNames.residency]}
          />
        </div>
        <div className={styles.formLine}>
          <Select
            selectedItem={
              formik.values[EFormNames.sexual_preference]
                ? getCurrentSelectItem(
                    formik.values[EFormNames.sexual_preference],
                    SEXUAL_PREFERENCE
                  )
                : undefined
            }
            name={EFormNames.sexual_preference}
            options={SEXUAL_PREFERENCE}
            handleChange={formik.setFieldValue}
            placeholder={'Sexual preference'}
            className={styles.typeBlock}
            width={250}
            error={formik.errors[EFormNames.sexual_preference]}
          />
          <Input
            name={EFormNames.shoe_size}
            placeholder={'Shoe size'}
            postFix={'UK'}
            className={styles.heightBlock}
            width={170}
            handleChange={formik.setFieldValue}
            value={formik.values[EFormNames.shoe_size]}
            error={formik.errors[EFormNames.shoe_size]}
            type={'number'}
          />
          <Input
            name={EFormNames.bust_size}
            placeholder={'Bust size'}
            postFix={'UK'}
            className={styles.heightBlock}
            width={235}
            handleChange={formik.setFieldValue}
            value={formik.values[EFormNames.bust_size]}
            error={formik.errors[EFormNames.bust_size]}
            type={'number'}
          />
          <Select
            selectedItem={
              formik.values[EFormNames.dress_size]
                ? getCurrentSelectItem(formik.values[EFormNames.dress_size], DRESS_SIZE)
                : undefined
            }
            name={EFormNames.dress_size}
            options={DRESS_SIZE}
            placeholder={'Dress size'}
            className={styles.typeBlock}
            handleChange={formik.setFieldValue}
            error={formik.errors[EFormNames.dress_size]}
          />
        </div>
        <TextArea
          mainLabel={'Personality traits'}
          smallLabel={
            'Define your personality traits. Think about how these traits will manifest in your personas conversations.'
          }
          value={formik.values[EFormNames.personality_traits]}
          error={formik.errors[EFormNames.personality_traits]}
          name={EFormNames.personality_traits}
          height={229}
          handleChange={formik.setFieldValue}
        />
        <TextArea
          mainLabel={'Communication style'}
          smallLabel={
            'Determine how you communicate. This will impact the tone of the personas responses.'
          }
          name={EFormNames.communication_style}
          height={121}
          handleChange={formik.setFieldValue}
          value={formik.values[EFormNames.communication_style]}
          error={formik.errors[EFormNames.communication_style]}
        />
        <TextArea
          mainLabel={'Interests and hobbies'}
          smallLabel={
            'What are your interests and hobbies? Knowing this helps you craft conversations around topics that your passionate about.'
          }
          value={formik.values[EFormNames.interests_and_hobbies]}
          error={formik.errors[EFormNames.interests_and_hobbies]}
          name={EFormNames.interests_and_hobbies}
          handleChange={formik.setFieldValue}
          height={229}
        />

        <TextArea
          mainLabel={'Background and story'}
          smallLabel={
            "Create your background story. Where are you from? What's your life story? This adds depth to the persona."
          }
          value={formik.values[EFormNames.background_and_story]}
          error={formik.errors[EFormNames.background_and_story]}
          name={EFormNames.background_and_story}
          handleChange={formik.setFieldValue}
          height={229}
        />
        <TextArea
          mainLabel={'Response limitations'}
          smallLabel={
            'Specify any topics or types of conversations that the persona should avoid or not engage in.'
          }
          value={formik.values[EFormNames.response_limitations]}
          error={formik.errors[EFormNames.response_limitations]}
          name={EFormNames.response_limitations}
          handleChange={formik.setFieldValue}
          height={121}
        />
      </>
    );
  }, [
    step,
    uploadedFileImage,
    setUploadedFileImage,
    uploadedFileVoice,
    setUploadedFileVoice,
    formik,
  ]);

  const handleBackClick = () => {
    if (step === EStep.form) {
      setIsShowPersonaForm(false);
      return;
    }
    if (step === EStep.image) {
      setStep(EStep.form);
      return;
    }
    if (step === EStep.audio) {
      setStep(EStep.image);
      return;
    }
  };
  const getIsDisable = () => {
    if (step === EStep.image) {
      return !uploadedFileSrcImage;
    }
    if (step === EStep.audio) {
      return !uploadedFileSrcVoice;
    }

    return false;
  };
  if (isShowSummary) {
    return (
      <PersonaSummary
        setIsShowSummary={setIsShowSummary}
        isEditing={isEditing}
        formik={formik}
        isLoading={isLoading}
        isPrepare={isPrepare}
        setIsPrepare={setIsPrepare}
      />
    );
  }
  return (
    <div className={styles.personaFormContent}>
      <ContentTitleBlock
        icon={contentData.icon}
        title={contentData.title}
        subTitle={contentData.subTitle}
        subTitleMaxWidth={contentData.subTitleMaxWidth}
      />
      <div className={styles.form}>
        <>
          {formContent}
          <Button
            text={'Continue'}
            onClick={handleNextClick}
            className={styles.formBtn}
            isDisable={getIsDisable()}
          />
          <TextButton text={'Back'} onClick={handleBackClick} />
        </>
      </div>
    </div>
  );
};

export default React.memo(PersonaForm);

import React from 'react';

import { DollarSmileIcon } from '../../assets/icons/DollarSmileIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import TextButton from '../../components/TextButton';
import { ROUTES } from '../../constants/routes';
import styles from './styles.module.scss';

const SendSuccess: React.FC = () => {
  return (
    <div className={styles.premiumContainer}>
      <ContentTitleBlock
        icon={<DollarSmileIcon />}
        title={'Your content has been sent'}
        subTitle={
          'Your content has been successfully sent to your Telegram audience. Keep an eye premium page, so you can track engagement and content sales.'
        }
        subTitleMaxWidth={749}
      />
      <div className={styles.btnBlock}>
        <Button
          text={'Send more'}
          onClick={() => (window.location.href = ROUTES.premium)}
          theme={'Primary'}
          className={styles.mainBtn}
        />
        <TextButton
          text={'Manage content'}
          onClick={() => (window.location.href = ROUTES.premium)}
        />
      </div>
    </div>
  );
};

export default React.memo(SendSuccess);

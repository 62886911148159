import { FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import $api from '../../../api/intercepter';
import { LogoIcon } from '../../../assets/icons/LogoIcon';
import Button from '../../../components/Button';
import ContentTitleBlock from '../../../components/ContentTitleBlock';
import Input from '../../../components/Input';
import TextButton from '../../../components/TextButton';
import { ROUTES } from '../../../constants/routes';
import { EHeader } from '../../../types/header';
import { errorHandler } from '../../../utils/errorHandler';
import { EUserKey } from '../constants';
import styles from '../styles.module.scss';

interface IProps {
  setIsEmailPart: (arg: boolean) => void;
  handleHeaderType?: (arg: EHeader) => void;
  formik: FormikProps<any>;
}

const EmailPart: React.FC<IProps> = ({ handleHeaderType, setIsEmailPart, formik }) => {
  const navigate = useNavigate();
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getIsEmailExist = () => {
    setIsLoading(true);
    $api
      .post('/email-available', { email: formik.values.email })
      .then((response) => {
        if (response.data.result[0]) {
          setSubmittedEmail(true);
          setIsEmailPart(false);
        } else if (response?.data.result[0] === false) {
          formik.setFieldError(EUserKey.email, 'Email already exists');
        }
      })
      .catch((error) => {
        errorHandler(error, enqueueSnackbar, formik);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (submittedEmail) {
      if (!formik.errors.email) {
        if (handleHeaderType) {
          handleHeaderType(EHeader.simple);
        }
        getIsEmailExist();
      }
    }
  }, [submittedEmail, formik.errors]);

  return (
    <div className={styles.emailPartContainer}>
      <ContentTitleBlock
        icon={<LogoIcon />}
        title={'Creating your digital reflection'}
        subTitle={'Request an account and start your AI companion journey'}
        width={520}
        titleMaxWidth={386}
      />
      <div className={styles.content}>
        <Input
          name={EUserKey.email}
          value={formik.values.email}
          placeholder={'Email address'}
          error={formik.errors.email as string}
          handleChange={formik.setFieldValue}
          onKeyDown={() => formik.validateField(EUserKey.email).then(() => setSubmittedEmail(true))}
        />
        <Button
          text={'Continue'}
          onClick={() => {
            formik.validateField(EUserKey.email).then(() => setSubmittedEmail(true));
          }}
          className={styles.mainBtn}
          isDisable={!formik.values.email}
          isLoading={isLoading}
        />
        <p className={styles.questionText}>Already have an account?</p>
        <TextButton
          text={'Sign back in'}
          onClick={() => {
            navigate(ROUTES.signIn);
          }}
          theme={'Secondary'}
          className={styles.txtBtn}
        />
      </div>
    </div>
  );
};

export default React.memo(EmailPart);

import clsx from 'clsx';
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { PasswordCloseEye } from '../../assets/icons/PasswordCloseEye';
import { PasswordOpenEye } from '../../assets/icons/PasswordOpenEye';
import styles from './styles.module.scss';

export type InputType = 'text' | 'password' | 'number';

interface InputProps {
  type?: InputType;
  width?: number;
  name: string;
  placeholder?: string;
  value?: string;
  readOnly?: boolean;
  postFix?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: any;
  error?: string;
  loading?: boolean;
  isDisabled?: boolean;
  handleChange?: (_name: string, _value: string) => void;
  onFocus?: (_name: string, _isTouched?: boolean) => void;
  onBlur?: () => void;
  onKeyDown?: () => void;
  onClick?: () => void;
  withMask?: boolean;
}

const Input: React.FC<InputProps> = ({
  type = 'text',
  name,
  placeholder = '',
  value = '',
  label,
  error = '',
  className,
  readOnly,
  loading,
  handleChange,
  onFocus,
  onBlur,
  onKeyDown,
  postFix,
  isDisabled,
  width,
  withMask = false,
  onClick,
}) => {
  const [currentInputType, setCurrentInputType] = useState(type);
  useEffect(() => {
    setCurrentInputType(type);
  }, [type]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    handleChange && handleChange(name, value);
  };
  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    onFocus && onFocus(name);
  };

  const handleEyeChange = () => {
    const changeParam = currentInputType === 'text' ? 'password' : 'text';
    setCurrentInputType(changeParam);
  };
  const enterKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onKeyDown?.();
    }
  };
  return (
    <>
      <div
        className={clsx(styles.inputWrapper, { [className]: className })}
        style={width ? { width: `${width}px` } : {}}
      >
        {label && <label className={styles.label}>{label}</label>}
        <div className={styles.inputContainer}>
          {withMask ? (
            <InputMask
              mask="9999 99 99"
              maskPlaceholder="DD MM YYYY"
              type={currentInputType}
              name={name}
              placeholder={'DD MM YYYY'}
              value={value}
              onChange={onChange}
              className={clsx(styles.input, {
                [styles.inputError]: !!error,
                [styles.readOnly]: readOnly && name !== 'selectInput',
              })}
              disabled={loading || isDisabled}
              onFocus={handleFocus}
              onBlur={onBlur}
              onKeyDown={enterKeyDown}
              readOnly={readOnly}
              style={name === 'selectInput' ? { cursor: 'pointer' } : {}}
            />
          ) : (
            <input
              type={currentInputType}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              className={clsx(styles.input, {
                [styles.inputError]: !!error,
                [styles.readOnly]: readOnly && name !== 'selectInput',
              })}
              disabled={loading || isDisabled}
              onFocus={handleFocus}
              onBlur={onBlur}
              onKeyDown={enterKeyDown}
              readOnly={readOnly}
              onClick={onClick}
              style={name === 'selectInput' ? { cursor: 'pointer' } : {}}
            />
          )}
          {postFix && !value && <div className={styles.postFix}>{postFix}</div>}
          {!postFix && type === 'password' && (
            <div onClick={handleEyeChange} className={styles.inputEye}>
              {currentInputType === 'password' ? <PasswordCloseEye /> : <PasswordOpenEye />}
            </div>
          )}
        </div>
        <p className={styles.errorMessage}>{error}</p>
      </div>
    </>
  );
};

export default Input;

import clsx from 'clsx';
import React from 'react';

import Loader from '../Loader';
import styles from './styles.module.scss';

export type Theme = 'Primary' | 'Secondary' | 'Negative';

interface IProps {
  theme?: Theme;
  text: string;
  onClick: () => void;
  isDisable?: boolean;
  isLoading?: boolean;
  className?: any;
}

const TextButton: React.FC<IProps> = ({
  theme = 'Primary',
  text,
  onClick,
  isDisable,
  isLoading,
  className,
}) => {
  const handleClick = () => {
    if (!isLoading && !isDisable) {
      onClick();
    }
  };
  return (
    <button
      onClick={handleClick}
      disabled={isDisable}
      className={clsx(
        styles[theme === 'Primary' ? 'primary' : theme === 'Secondary' ? 'secondary' : 'negative'],
        {
          [className]: className,
        }
      )}
    >
      {isLoading ? <Loader /> : text}
    </button>
  );
};

export default TextButton;

import React from 'react';

export const PasswordOpenEye: React.FC = () => {
  return (
    <svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="6.25" r="3" fill="black" />
      <path
        d="M1.5 6.25C2.83333 4.25 6.6 0.75 11 0.75C15.4 0.75 19.1667 4.25 20.5 6.25"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
};

import React, { useContext } from 'react';

import { LockIcon } from '../../../../assets/icons/LockIcon';
import Button from '../../../../components/Button';
import ContentTitleBlock from '../../../../components/ContentTitleBlock';
import TextButton from '../../../../components/TextButton';
import { MainContext } from '../../../../povider/MainProvider';
import styles from './styles.module.scss';

const PremiumModal: React.FC = () => {
  const { setModalType } = useContext(MainContext);
  return (
    <div className={styles.premiumModal}>
      <ContentTitleBlock
        icon={<LockIcon />}
        title={'Unlock premium and maximise your earnings'}
        subTitle={
          'Contact us to upgrade to a Premium account and open the door to a world of unparalleled opportunities. With our Premium account, you gain access to the remarkable feature of selling images and video content directly to your Telegram audience.'
        }
        subTitleMaxWidth={616}
        titleMaxWidth={519}
      />
      <div className={styles.btnBlock}>
        <Button
          text={'Contact us'}
          onClick={() => (window.location.pathname = '/support')}
          className={styles.mainBtn}
        />{' '}
        <TextButton
          text={'Close'}
          onClick={() => {
            setModalType('');
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(PremiumModal);

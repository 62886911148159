import { memo } from 'react';
import { FileWithPath } from 'react-dropzone';

import styles from './styles.module.scss';

interface IAudioProps {
  file: FileWithPath | null;
  formattedTime: string;
  audioDuration: number;
  handleRemoveAudio: () => void;
}

const Audio = ({ file, formattedTime, handleRemoveAudio, audioDuration }: IAudioProps) => {
  return (
    <div className={styles.audio}>
      <div className={styles.audioInfo}>
        <h1 className={styles.title}>{file?.path || 'Voice'}</h1>
        <p className={styles.time} style={{ visibility: audioDuration ? 'initial' : 'hidden' }}>
          {formattedTime}
        </p>
      </div>
      <button onClick={handleRemoveAudio} className={styles.delete}>
        Delete
      </button>
    </div>
  );
};

export default memo(Audio);

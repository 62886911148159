import React from 'react';

import LoaderPage from '../../components/LoaderPage.tsx';
import MainFrame from '../../features/MainFrame';
import { useAuth } from '../../hooks/useAuth';
import { EHeader } from '../../types/header';
import PremiumWelcome from './PremiumWelcome';

const Premium: React.FC = () => {
  const { isLoading } = useAuth();
  if (isLoading) {
    return <LoaderPage />;
  }
  return (
    <MainFrame headerType={EHeader.advanced}>
      <PremiumWelcome />
    </MainFrame>
  );
};

export default React.memo(Premium);

import React from 'react';

import LoaderPage from '../../components/LoaderPage.tsx';
import MainFrame from '../../features/MainFrame';
import { useAuth } from '../../hooks/useAuth';
import SignIn from './SignIn';

const Auth: React.FC = () => {
  const { isLoading } = useAuth(true);

  if (isLoading) {
    return <LoaderPage />;
  }
  return (
    <MainFrame>
      <SignIn />
    </MainFrame>
  );
};

export default React.memo(Auth);

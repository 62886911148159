import { FormikProps } from 'formik';
import { EnqueueSnackbar } from 'notistack';

export interface IError {
  response: {
    data: {
      error: Record<string, string>;
    };
  };
}
const DEFAULT_ERROR_KEY = 'non_field_errors';

export const errorHandler = (
  error: IError,
  notification: EnqueueSnackbar,
  formik?: FormikProps<any>
) => {
  const errorData = error?.response?.data.error;

  if (errorData) {
    Object.keys(errorData).forEach((key) => {
      if (key === DEFAULT_ERROR_KEY) {
        notification(errorData[key], { variant: 'error' });
      } else if (formik) {
        formik.setFieldError(key, errorData[key]);
      }
    });
  }
};

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';

import $api from '../../api/intercepter';
import { BankCardIcon } from '../../assets/icons/BankCardIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import Input from '../../components/Input';
import { EHeader } from '../../types/header';
import { IPayoutMethod } from '../../types/user';
import { errorHandler } from '../../utils/errorHandler';
import AfterEditBankCard from './AfterEditBankCard';
import { EPayoutsKey, INITIAL_VALUES, schema } from './constants';
import ReadyPayout from './ReadyPayout';
import styles from './styles.module.scss';

interface IProps {
  isEditing?: boolean;
  payoutData?: IPayoutMethod;
  handleHeaderType?: (arg?: EHeader) => void;
}
const BankCard: React.FC<IProps> = ({ isEditing, payoutData, handleHeaderType }) => {
  const [isSave, setIsSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: schema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      setIsLoading(true);
      $api[isEditing ? 'patch' : 'post']('/payout', {
        [EPayoutsKey.account_holder_name]: values[EPayoutsKey.account_holder_name],
        [EPayoutsKey.account_number]: values[EPayoutsKey.account_number],
        [EPayoutsKey.sort_code]: values[EPayoutsKey.sort_code],
        [EPayoutsKey.iban]: values[EPayoutsKey.iban],
      })
        .then(() => {
          handleHeaderType && handleHeaderType(EHeader.simple);
          setIsSave(true);
        })
        .catch((error) => {
          errorHandler(error, enqueueSnackbar, formik);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });
  useEffect(() => {
    if (payoutData && isEditing) {
      formik.setValues({
        [EPayoutsKey.account_holder_name]: payoutData[EPayoutsKey.account_holder_name],
        [EPayoutsKey.account_number]: payoutData[EPayoutsKey.account_number],
        [EPayoutsKey.sort_code]: payoutData[EPayoutsKey.sort_code],
        [EPayoutsKey.iban]: payoutData[EPayoutsKey.iban],
      });
    }
  }, [payoutData]);

  const component = useMemo(() => {
    if (isSave) {
      return isEditing ? <AfterEditBankCard /> : <ReadyPayout />;
    }
    return (
      <>
        <ContentTitleBlock
          icon={<BankCardIcon />}
          title={isEditing ? 'Edit bank details' : 'Add bank details'}
          subTitle={
            isEditing
              ? "Need to make changes to your bank details? No problem. We've got you covered."
              : "Add your bank details to receive your earnings from your AI companion's performance. Our secure and seamless payment system ensures that your payments are processed efficiently"
          }
          subTitleMaxWidth={isEditing ? 463 : 702}
        />
        <div className={styles.content}>
          <Input
            name={EPayoutsKey.account_holder_name}
            placeholder={'Account holders name'}
            handleChange={formik.setFieldValue}
            value={formik.values[EPayoutsKey.account_holder_name]}
            error={formik.errors[EPayoutsKey.account_holder_name]}
            onKeyDown={() => formik.handleSubmit()}
          />
          <Input
            name={EPayoutsKey.account_number}
            placeholder={'Account number'}
            handleChange={formik.setFieldValue}
            value={formik.values[EPayoutsKey.account_number]}
            error={formik.errors[EPayoutsKey.account_number]}
            onKeyDown={() => formik.handleSubmit()}
          />
          <Input
            name={EPayoutsKey.sort_code}
            placeholder={'Sort code'}
            handleChange={formik.setFieldValue}
            value={formik.values[EPayoutsKey.sort_code]}
            error={formik.errors[EPayoutsKey.sort_code]}
            onKeyDown={() => formik.handleSubmit()}
          />
          <Input
            name={EPayoutsKey.iban}
            placeholder={'IBAN (If applicable)'}
            handleChange={formik.setFieldValue}
            value={formik.values[EPayoutsKey.iban]}
            error={formik.errors[EPayoutsKey.iban]}
            onKeyDown={() => formik.handleSubmit()}
          />
          <Button
            text={isEditing ? 'Save' : 'Add payout'}
            onClick={formik.handleSubmit}
            isLoading={isLoading}
            isDisable={!formik.values[EPayoutsKey.account_holder_name]}
          />
        </div>
      </>
    );
  }, [isSave, isEditing, formik]);
  return <div className={styles.payoutsContainer}>{component}</div>;
};

export default React.memo(BankCard);

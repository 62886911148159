import { TFileInputType } from '../components/FileInput/types';

export const getFileType = (file: File): TFileInputType => {
  if (file.type.includes('audio')) {
    return 'audio';
  } else if (file.type.includes('image')) {
    return 'image';
  } else if (file.type.includes('video')) {
    return 'video';
  }
  return 'premium';
};

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';

import { $multipart_api } from '../api/intercepter';
import { EFormNames, INITIAL_VALUES, schema } from '../pages/persona/constants';
import { EStep } from '../pages/persona/PersonaForm';
import { IPersona } from '../types/user';
import { errorHandler } from '../utils/errorHandler';

export const usePersona = (isEditing?: boolean, personaData?: IPersona) => {
  const [step, setStep] = useState(EStep.form);
  const [isShowSummary, setIsShowSummary] = useState(false);
  const [isFormValidate, setIsFormValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrepare, setIsPrepare] = useState(false);
  const [uploadedFileImage, setUploadedFileImage] = useState<FileWithPath | null>(null);
  const [uploadedFileSrcImage, setUploadedFileSrcImage] = useState<string>('');
  const [uploadedFileVoice, setUploadedFileVoice] = useState<FileWithPath | null>(null);
  const [uploadedFileSrcVoice, setUploadedFileSrcVoice] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: schema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      setIsLoading(true);
      const formData = new FormData();
      const personaData = { ...values };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete personaData[EFormNames.voice];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete personaData[EFormNames.picture];
      formData.append('persona', JSON.stringify(personaData));
      formData.append('picture', formik.values[EFormNames.picture]);
      formData.append('voice', formik.values[EFormNames.voice]);
      $multipart_api[isEditing ? 'patch' : 'post']('/persona', formData)
        .then(() => {
          setIsPrepare(true);
        })
        .catch((error) => {
          errorHandler(error, enqueueSnackbar, formik);
          setIsShowSummary(false);
          if (error?.response?.data?.error?.voice) {
            setStep(error?.response?.data?.error?.voice);
          } else if (error?.response?.data?.error?.picture) {
            setStep(EStep.image);
          } else {
            setStep(EStep.form);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });
  useEffect(() => {
    if (personaData) {
      formik.setValues({
        [EFormNames.background_and_story]: personaData.background_story,
        [EFormNames.body_type]: personaData.body_type,
        [EFormNames.bust_size]: personaData.bust_size,
        [EFormNames.communication_style]: personaData.communication_style,
        [EFormNames.dress_size]: personaData.dress_size,
        [EFormNames.ethnicity]: personaData.ethnicity,
        [EFormNames.full_name]: personaData.full_name,
        [EFormNames.interests_and_hobbies]: personaData.interests_hobbies,
        [EFormNames.personality_traits]: personaData.personality_traits,
        [EFormNames.picture]: personaData.picture_file_url || '',
        [EFormNames.residency]: personaData.residency,
        [EFormNames.response_limitations]: personaData.response_limitation,
        [EFormNames.sexual_preference]: personaData.sexual_preference,
        [EFormNames.shoe_size]: personaData.shoe_size,
        [EFormNames.telegram]: personaData.telegram,
        [EFormNames.voice]: personaData.voice_file_url || '',
        [EFormNames.height]: personaData.height,
        [EFormNames.age]: personaData.age,
      });
      setUploadedFileSrcImage(personaData.picture_file_url || '');
      setUploadedFileSrcVoice(personaData.voice_file_url || '');
    }
  }, [personaData]);

  useEffect(() => {
    formik.setFieldValue(EFormNames.picture, uploadedFileImage);
  }, [uploadedFileImage]);

  useEffect(() => {
    formik.setFieldValue(EFormNames.voice, uploadedFileVoice);
  }, [uploadedFileVoice]);

  useEffect(() => {
    if (isFormValidate && !Object.keys(formik.errors).length) {
      setStep(EStep.image);
      setIsFormValidate(false);
    } else {
      window.scrollTo({
        top: 300,
        behavior: 'smooth',
      });
    }
  }, [isFormValidate, formik.errors]);
  const handleNextClick = () => {
    if (step === EStep.form) {
      setIsFormValidate(false);

      Promise.all([
        formik.validateField(EFormNames.full_name),
        formik.validateField(EFormNames.telegram),
        formik.validateField(EFormNames.age),
        formik.validateField(EFormNames.height),
        formik.validateField(EFormNames.body_type),
        formik.validateField(EFormNames.ethnicity),
        formik.validateField(EFormNames.residency),
        formik.validateField(EFormNames.sexual_preference),
        formik.validateField(EFormNames.shoe_size),
        formik.validateField(EFormNames.bust_size),
        formik.validateField(EFormNames.dress_size),
        formik.validateField(EFormNames.personality_traits),
        formik.validateField(EFormNames.communication_style),
        formik.validateField(EFormNames.interests_and_hobbies),
        formik.validateField(EFormNames.background_and_story),
        formik.validateField(EFormNames.response_limitations),
      ]).then(() => {
        setIsFormValidate(true);
      });
      return;
    }
    if (step === EStep.image) {
      setStep(EStep.audio);
      return;
    }
    if (step === EStep.audio) {
      setIsShowSummary(true);
      return;
    }
  };
  return {
    step,
    uploadedFileImage,
    isShowSummary,
    isLoading,
    formik,
    uploadedFileSrcImage,
    uploadedFileVoice,
    isPrepare,
    uploadedFileSrcVoice,
    handleNextClick,
    setIsShowSummary,
    setIsPrepare,
    setUploadedFileSrcVoice,
    setIsFormValidate,
    setStep,
    setUploadedFileImage,
    setUploadedFileSrcImage,
    setUploadedFileVoice,
  };
};

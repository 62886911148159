import * as yup from 'yup';

export const SEXUAL_PREFERENCE = [
  { value: 'asexual', label: 'Asexual' },
  { value: 'androgynosexual', label: 'Androgynosexual' },
  { value: 'bisexual', label: 'Bisexual' },
  { value: 'demisexual', label: 'Demisexual' },
  { value: 'graysexual', label: 'Graysexual' },
  { value: 'heterosexual', label: 'Heterosexual' },
  { value: 'pansexual', label: 'Pansexual' },
  { value: 'polysexual', label: 'Polysexual' },
  { value: 'queer', label: 'Queer' },
  { value: 'sapiosexual', label: 'Sapiosexual' },
  { value: 'Skoliosexual', label: 'Skoliosexual' },
];

export const BODY_TYPE = [
  { value: 'athletic', label: 'Athletic' },
  { value: 'curvy', label: 'Curvy' },
  { value: 'full_figured', label: 'Full-Figured' },
  { value: 'lean', label: 'Lean' },
  { value: 'muscular', label: 'Muscular' },
  { value: 'petite', label: 'Petite' },
  { value: 'slim', label: 'Slim' },
  { value: 'Voluptuous', label: 'Voluptuous' },
];

export const DRESS_SIZE = [
  { value: '6', label: '6' },
  { value: '8', label: '8' },
  { value: '10', label: '10' },
  { value: '12', label: '12' },
  { value: '14+', label: '14+' },
];
export enum EFormNames {
  full_name = 'full_name',
  telegram = 'telegram',
  age = 'age',
  height = 'height',
  body_type = 'body_type',
  ethnicity = 'ethnicity',
  residency = 'residency',
  sexual_preference = 'sexual_preference',
  shoe_size = 'shoe_size',
  bust_size = 'bust_size',
  dress_size = 'dress_size',
  personality_traits = 'personality_traits',
  communication_style = 'communication_style',
  interests_and_hobbies = 'interests_hobbies',
  background_and_story = 'background_story',
  response_limitations = 'response_limitation',
  picture = 'picture',
  voice = 'voice',
}

export const INITIAL_VALUES = {
  [EFormNames.full_name]: '',
  [EFormNames.telegram]: '',
  [EFormNames.age]: '',
  [EFormNames.height]: '',
  [EFormNames.body_type]: '',
  [EFormNames.ethnicity]: '',
  [EFormNames.residency]: '',
  [EFormNames.sexual_preference]: '',
  [EFormNames.shoe_size]: '',
  [EFormNames.bust_size]: '',
  [EFormNames.dress_size]: '',
  [EFormNames.personality_traits]: '',
  [EFormNames.communication_style]: '',
  [EFormNames.interests_and_hobbies]: '',
  [EFormNames.background_and_story]: '',
  [EFormNames.response_limitations]: '',
  [EFormNames.picture]: '',
  [EFormNames.voice]: '',
};

export const schema = yup.object().shape({
  [EFormNames.full_name]: yup
    .string()
    .required('Required')
    .min(1, 'must be min 3 characters')
    .max(50, 'must be max 50 characters'),
  [EFormNames.telegram]: yup
    .string()
    .required('Required')
    .min(8, 'must be min 8 characters')
    .max(20, 'must be  max 20 characters'),
  [EFormNames.age]: yup.string().required('Required'),
  [EFormNames.height]: yup.string().required('Required'),
  [EFormNames.body_type]: yup.string().required('Required'),
  [EFormNames.ethnicity]: yup.string().required('Required'),
  [EFormNames.residency]: yup.string().required('Required'),
  [EFormNames.sexual_preference]: yup.string().required('Required'),
  [EFormNames.bust_size]: yup.string().required('Required'),
  [EFormNames.shoe_size]: yup.string().required('Required'),
  [EFormNames.dress_size]: yup.string().required('Required'),
  [EFormNames.personality_traits]: yup
    .string()
    .required('Required')
    .min(50, 'must be min 50 characters')
    .max(8000, 'must be max 8000 characters'),
  [EFormNames.communication_style]: yup
    .string()
    .required('Required')
    .min(50, 'must be min 50 characters')
    .max(8000, 'must be max 8000 characters'),
  [EFormNames.interests_and_hobbies]: yup
    .string()
    .required('Required')
    .min(50, 'must be min 50 characters')
    .max(8000, 'must be max 8000 characters'),
  [EFormNames.background_and_story]: yup
    .string()
    .required('Required')
    .min(50, 'must be min 50 characters')
    .max(8000, 'must be max 8000 characters'),
  [EFormNames.response_limitations]: yup
    .string()
    .required('Required')
    .min(50, 'must be min 50 characters')
    .max(8000, 'must be max 8000 characters'),
});

import React from 'react';

import LoaderPage from '../../components/LoaderPage.tsx';
import MainFrame from '../../features/MainFrame';
import { useAuth } from '../../hooks/useAuth';
import { EHeader } from '../../types/header';
import PayoutComponent from './PayoutComponent';

const Payouts = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <MainFrame headerType={EHeader.advanced}>
      <PayoutComponent />
    </MainFrame>
  );
};

export default React.memo(Payouts);

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import $api from '../../api/intercepter';
import { LogoIcon } from '../../assets/icons/LogoIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import Input from '../../components/Input';
import TextButton from '../../components/TextButton';
import { ROUTES } from '../../constants/routes';
import { MainContext } from '../../povider/MainProvider';
import { errorHandler } from '../../utils/errorHandler';
import styles from './styles.module.scss';

const INITIAL_VALUES = {
  email: '',
  password: '',
};
const schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  // password: Yup.string().matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\\$%\\^&\\*])(?=.{8,})/,
  //   'Password must contain at least 8 characters, including one uppercase, one lowercase, one digit or special character'
  // ),
  password: Yup.string().required('Required').min(10, 'Password must be min 10 characters'),
});

const SignIn: React.FC = () => {
  const { setIsAuthenticated, setUserData } = useContext(MainContext);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setIsLoading(true);
      $api
        .post('/login', { email: values.email, password: values.password })
        .then((response) => {
          if (response?.data) {
            localStorage.setItem('token', response?.data?.result[0].jwt_token);
            $api
              .get('/whoami')
              .then(({ data }) => {
                setIsAuthenticated(true);
                setUserData(data.result[0]);
                navigate(ROUTES.persona);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);

          errorHandler(error, enqueueSnackbar, formik);
        });
    },
  });

  const navigate = useNavigate();
  return (
    <div className={styles.signInContainer}>
      <ContentTitleBlock
        icon={<LogoIcon />}
        title={'Creating your digital reflection'}
        width={386}
      />
      <div className={styles.content}>
        <Input
          name={'email'}
          placeholder={'Email address'}
          value={formik.values.email}
          error={formik.errors.email as string}
          handleChange={formik.setFieldValue}
          onKeyDown={formik.handleSubmit}
        />
        <Input
          name={'password'}
          placeholder={'Password'}
          type={'password'}
          className={styles.passInput}
          value={formik.values.password}
          error={formik.errors.password as string}
          handleChange={formik.setFieldValue}
          onKeyDown={formik.handleSubmit}
        />
        <div
          className={styles.forgotPassBtn}
          onClick={() => {
            navigate(ROUTES.forgotPassword);
          }}
        >
          Forgotten password?
        </div>
        <Button
          text={'Sign in'}
          onClick={() => {
            Promise.all([formik.validateField('email')]).then(() => {
              formik.handleSubmit();
            });
          }}
          className={styles.mainBtn}
          isLoading={isLoading}
          isDisable={!formik.values.email || !formik.values.password}
        />
        <p className={styles.questionText}>Don’t have an account?</p>
        <TextButton
          text={'Request one'}
          onClick={() => {
            navigate(ROUTES.home);
          }}
          theme={'Secondary'}
          isDisable={isLoading}
        />
      </div>
    </div>
  );
};

export default React.memo(SignIn);

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';

import { $multipart_api } from '../api/intercepter';
import { EPremiumFormNames, INITIAL_VALUES, schema } from '../pages/premium/constants';
import { errorHandler } from '../utils/errorHandler';

export const usePremium = (isEditing?: boolean) => {
  const [isShowSummary, setIsShowSummary] = useState(false);
  const [isFormValidate, setIsFormValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<FileWithPath | null>(null);
  const [uploadedFileSrc, setUploadedFileSrc] = useState<string>('');
  const [teaserFile, setTeaserFile] = useState<FileWithPath | null>(null);
  const [teaserFileFileSrc, setTeaserFileSrc] = useState<string>('');
  const [isForm, setIsForm] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: schema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(
        'premium_content',
        JSON.stringify({ ...values, content_type: uploadedFile?.type })
      );
      uploadedFile && formData.append('file', uploadedFile);
      teaserFile && formData.append('file_teaser', teaserFile);
      $multipart_api[isEditing ? 'patch' : 'post']('/premiumcontent', formData)
        .then(() => {
          setIsUploaded(true);
        })
        .catch((error) => {
          errorHandler(error, enqueueSnackbar, formik);
          setIsShowSummary(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    if (isFormValidate && !Object.keys(formik.errors).length) {
      setIsShowSummary(true);
    } else {
      window.scrollTo({
        top: 300,
        behavior: 'smooth',
      });
    }
    setIsFormValidate(false);
  }, [isFormValidate, formik.errors]);
  const handleBtnClick = () => {
    if (isForm) {
      Promise.all([
        formik.validateField(EPremiumFormNames.title),
        formik.validateField(EPremiumFormNames.price),
        formik.validateField(EPremiumFormNames.tags),
        formik.validateField(EPremiumFormNames.message),
      ]).then(() => setIsFormValidate(true));
    } else {
      setIsForm(true);
    }
  };

  return {
    isForm,
    isUploaded,
    setIsUploaded,
    isShowSummary,
    isLoading,
    formik,
    uploadedFileSrc,
    uploadedFile,
    teaserFile,
    teaserFileFileSrc,
    handleBtnClick,
    setIsForm,
    setUploadedFileSrc,
    setUploadedFile,
    setIsShowSummary,
    setIsFormValidate,
    setTeaserFile,
    setTeaserFileSrc,
  };
};

import { clsx } from 'clsx';
import React, { ReactNode } from 'react';

import styles from './styles.module.scss';

interface IProps {
  icon?: ReactNode;
  title?: string;
  subTitle?: string;
  className?: any;
  width?: number;
  titleMaxWidth?: number;
  subTitleMaxWidth?: number;
}
const ContentTitleBlock: React.FC<IProps> = ({
  icon,
  width,
  title,
  className,
  subTitle,
  titleMaxWidth,
  subTitleMaxWidth,
}) => {
  return (
    <div
      className={clsx(styles.container, { [className]: className })}
      style={width ? { maxWidth: `${width}px` } : {}}
    >
      {!!icon && <div>{icon}</div>}
      {!!title && (
        <p className={styles.title} style={titleMaxWidth ? { maxWidth: `${titleMaxWidth}px` } : {}}>
          {title}
        </p>
      )}
      {!!subTitle && (
        <p
          className={styles.subTitle}
          style={subTitleMaxWidth ? { maxWidth: `${subTitleMaxWidth}px` } : {}}
        >
          {subTitle}
        </p>
      )}
    </div>
  );
};
export default React.memo(ContentTitleBlock);

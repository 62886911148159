import { IFileInputTypeValues } from './types';

export const FILE_INPUT_TYPES: Record<string, IFileInputTypeValues> = {
  image: {
    formats: ['JPEG', 'JPG', 'PNG'],
    accept: 'image/*',
    acceptValues: ['.jpeg', '.JPEG', '.jpg', '.JPG', '.png', '.PNG'],
    sizeLimit: 1000000,
  },
  audio: {
    formats: ['MP3', 'WAV', 'ACC', 'Windows audio file'],
    accept: 'audio/mp3',
    acceptValues: ['.mp3', '.MP3', '.wav', '.WAV', '.acc', '.ACC'],
    sizeLimit: 50000000,
  },
  video: {
    formats: ['MP4', 'MOV', 'MPEG-4'],
    accept: 'video/mp4,video/mov,video/mpeg-4',
    acceptValues: ['.mp4', '.MP4', '.mov', '.MOV', '.mpeg-4', '.MPEG-4'],
    sizeLimit: 100000000000000,
  },

  premium: {
    formats: [
      'MP4',
      'MOV',
      'MPEG-4',
      'JPEG',
      'JPG',
      'PNG',
      'MP3',
      'WAV',
      'ACC',
      'Windows audio file',
    ],
    accept: 'video/mp4,video/mov,video/mpeg-4,image/jpeg,image/jpg,image/png,audio/mp3',
    acceptValues: [
      '.mp4',
      '.MP4',
      '.mov',
      '.MOV',
      '.mpeg-4',
      '.MPEG-4',
      '.jpeg',
      '.JPEG',
      '.jpg',
      '.JPG',
      '.png',
      '.PNG',
      '.mp3',
      '.MP3',
      '.wav',
      '.WAV',
      '.acc',
      '.ACC',
    ],
    sizeLimit: 100000000000000,
  },
};

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import $api from '../../api/intercepter';
import { ForgotPassIcon } from '../../assets/icons/ForgotPassIcon';
import { MailBoxIcon } from '../../assets/icons/MailBoxIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import Input from '../../components/Input';
import LoaderPage from '../../components/LoaderPage.tsx';
import TextButton from '../../components/TextButton';
import { ROUTES } from '../../constants/routes';
import MainFrame from '../../features/MainFrame';
import { useAuth } from '../../hooks/useAuth';
import { EHeader } from '../../types/header';
import { errorHandler } from '../../utils/errorHandler';
import styles from './styles.module.scss';

const INITIAL_VALUES = {
  email: '',
};

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});
const ForgotPassword: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const { isLoading: isAuthLoading } = useAuth(true);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: schema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      setIsLoading(true);
      $api
        .post('/password/request', {
          email: values.email,
        })
        .then((response) => {
          if (response.data.result[0]) {
            setSubmittedEmail(true);
          } else {
            formik.setFieldError('email', 'User not exist');
          }
        })
        .catch((error) => {
          errorHandler(error, enqueueSnackbar, formik);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });
  useEffect(() => {
    if (submittedEmail) {
      if (!formik.errors.email) {
        setIsSuccess(true);
      }
    }
  }, [submittedEmail, formik.errors]);
  if (isAuthLoading) {
    return <LoaderPage />;
  }

  return (
    <MainFrame headerType={EHeader.simple}>
      <div className={styles.forgotPasswordContainer}>
        <ContentTitleBlock
          icon={isSuccess ? <MailBoxIcon /> : <ForgotPassIcon />}
          title={isSuccess ? 'We’ve sent you an email' : 'Need a reminder?'}
          subTitle={
            isSuccess
              ? 'Check your email address and follow the instructions included in the message'
              : 'We just need your registered email address to send you password reset instructions'
          }
          subTitleMaxWidth={isSuccess ? 428 : 545}
        />
        <div className={styles.content}>
          {isSuccess ? (
            <TextButton
              text={'Back to Sign in'}
              onClick={() => {
                navigate(ROUTES.signIn);
              }}
              theme={'Secondary'}
            />
          ) : (
            <>
              <Input
                name={'email'}
                placeholder={'Email'}
                value={formik.values.email}
                error={formik.errors.email as string}
                handleChange={formik.setFieldValue}
                onKeyDown={() => formik.validateField('email').then(() => formik.handleSubmit())}
              />
              <Button
                text={'Continue'}
                onClick={() => {
                  formik.validateField('email').then(() => formik.handleSubmit());
                }}
                className={styles.continueBtn}
                isLoading={isLoading}
                isDisable={!formik.values.email}
              />
              <TextButton
                text={'Back'}
                isDisable={isLoading}
                onClick={() => {
                  navigate(ROUTES.signIn);
                }}
              />
            </>
          )}
        </div>
      </div>
    </MainFrame>
  );
};

export default React.memo(ForgotPassword);

import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const interceptor = (contentType?: string) => {
  const newAxios = axios.create({
    baseURL: 'https://app.aillusion.ai/api',
  });

  newAxios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token');

    if (config && config.headers) {
      config.headers['Content-Type'] = contentType || 'application/json';
    }
    if (token) {
      config.headers['JWT-Token'] = token;
    }
    return config;
  });

  newAxios.interceptors.response.use(
    (response: AxiosResponse) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error: any) => {
      if (error?.response?.status === 401) {
        // const { pathname, search } = window.location;
        // newAxios.store?.dispatch(setRedirectUrl(pathname + search));
        // logout(newAxios.store?.dispatch);
        // newAxios.store?.dispatch(
        //   setNotification({ message: 'Unauthorized: Please login again', type: 'error' })
        // );
      }
      return Promise.reject(error);
    }
  );
  return newAxios;
};
const $api = interceptor();
export const $multipart_api = interceptor('multipart/form-data');
export default $api;

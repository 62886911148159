import clsx from 'clsx';
import { memo, useMemo } from 'react';

import { useFileInput } from '../../hooks/useFileInput';
import Audio from '../Audio';
import CroppedImage from '../CroppedImage';
import Video from '../Video';
import { FILE_INPUT_TYPES } from './constants';
import styles from './styles.module.scss';
import { IFileInputProps, TUploadedFileType } from './types';

const FileInput = ({
  type,
  text,
  title,
  isDisabled,
  uploadedFile,
  setUploadedFile,
  uploadedFileSrc,
  setUploadedFileSrc,
  setFormValue,
  isTeaser,
}: IFileInputProps) => {
  const {
    getRootProps,
    getInputProps,
    croppedImage,
    crop,
    zoom,
    onCropComplete,
    uploadedPremiumFIleType,
    handleCropChange,
    setZoom,
    handleZoom,
    handleRemoveImg,
    handleRemoveAudio,
    handleRemoveVideo,
    formattedTime,
    audioDuration,
  } = useFileInput(
    type,
    uploadedFileSrc,
    setUploadedFile,
    setUploadedFileSrc,
    setFormValue,
    uploadedFile
  );

  const acceptedFileTypes = useMemo(
    () => FILE_INPUT_TYPES[type].formats.join(', '),
    [FILE_INPUT_TYPES, type]
  );

  const isTypeOf = (val: TUploadedFileType) => {
    return type === val;
  };

  return (
    <section className={styles.mainSection}>
      <div className={styles.mainWrapper}>
        {title && <p className={styles.wrapperTitle}>{title}</p>}
        <div
          className={clsx(!isTypeOf('audio') ? styles.mainImageWrapper : styles.mainAudioWrapper, {
            [styles.emptyAudioBlock]: isTypeOf('audio') && !uploadedFile && !uploadedFileSrc,
            [styles.disabled]: isDisabled,
          })}
          style={uploadedFileSrc ? { border: 'none' } : {}}
        >
          {!uploadedFileSrc && (
            <div {...getRootProps()} style={{ cursor: 'pointer' }}>
              <input {...getInputProps()} />
              <p className={styles.title}>
                Drop a file here, or <span>browse</span>
              </p>
              {!uploadedFileSrc && <p className={styles.typesTitle}>{acceptedFileTypes}</p>}
            </div>
          )}
          {(isTypeOf('image') || (type === 'premium' && uploadedPremiumFIleType === 'image')) &&
            uploadedFileSrc && (
              <CroppedImage
                src={uploadedFileSrc}
                crop={crop}
                zoom={zoom}
                onCropComplete={onCropComplete}
                onCropChange={(e: { x: number; y: number }) => handleCropChange(e)}
                setZoom={setZoom}
                handleZoom={handleZoom}
                croppedImage={croppedImage}
                handleRemoveImg={handleRemoveImg}
                isShowZoomControl={!(type === 'premium' || isTeaser)}
              />
            )}
          {(isTypeOf('audio') || (type === 'premium' && uploadedPremiumFIleType === 'audio')) &&
            uploadedFileSrc && (
              <Audio
                file={uploadedFile}
                formattedTime={formattedTime}
                handleRemoveAudio={handleRemoveAudio}
                audioDuration={audioDuration}
              />
            )}
          {(isTypeOf('video') || (type === 'premium' && uploadedPremiumFIleType === 'video')) &&
            uploadedFileSrc && (
              <Video src={uploadedFileSrc} handleRemoveVideo={handleRemoveVideo} />
            )}
        </div>
        {text && <p className={styles.text}>{text}</p>}
        {isTypeOf('audio') && (
          <p className={styles.text}>
            Please ensure that there is no background noise, as this effects the quality of the
            Personas voice
          </p>
        )}
      </div>
    </section>
  );
};

export default memo(FileInput);

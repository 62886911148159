export const InfoIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 4.17285C6.58984 4.17285 6.26172 3.84473 6.26172 3.44629C6.26172 3.04199 6.58984 2.71387 7 2.71387C7.41602 2.71387 7.73828 3.04199 7.73828 3.44629C7.73828 3.84473 7.41602 4.17285 7 4.17285ZM6.36719 11.2861V5.12207H7.63281V11.2861H6.36719Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" stroke="white" />
    </svg>
  );
};

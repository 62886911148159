import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';

import $api from '../../../../api/intercepter';
import { RubbishIcon } from '../../../../assets/icons/RubbishIcon';
import Button from '../../../../components/Button';
import ContentTitleBlock from '../../../../components/ContentTitleBlock';
import TextButton from '../../../../components/TextButton';
import { MainContext } from '../../../../povider/MainProvider';
import styles from './styles.module.scss';

const DeleteModal: React.FC = () => {
  const { setModalType } = useContext(MainContext);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = async () => {
    setIsLoading(true);

    const id = await sessionStorage.getItem('deleteId');
    $api
      .delete('/premiumcontent', { data: { id: Number(id) } })
      .then(() => {
        window.location.reload();
        sessionStorage.removeItem('deleteId');
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className={styles.modalWrapper}>
      <ContentTitleBlock
        icon={<RubbishIcon />}
        title={'Deleting content'}
        subTitle={
          'Deleting this content will remove it from the platform permanently. Please confirm your choice to proceed.'
        }
        subTitleMaxWidth={525}
      />
      <div className={styles.btnBlock}>
        <Button
          text={'Confirm'}
          onClick={handleConfirm}
          className={styles.mainBtn}
          isLoading={isLoading}
        />
        <TextButton
          text={'Cancel'}
          onClick={() => {
            setModalType('');
          }}
          theme={'Negative'}
          isDisable={isLoading}
        />
      </div>
    </div>
  );
};

export default React.memo(DeleteModal);

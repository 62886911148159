import getBlobDuration from 'get-blob-duration';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';

import { FILE_INPUT_TYPES } from '../components/FileInput/constants';
import { TFileInputType, TUploadedFileType } from '../components/FileInput/types';
import { EFormNames } from '../pages/persona/constants';
import { getFileType } from '../utils/getFileType';
import useSecondsConverter from './useSecondsConverter';

export const useFileInput = (
  type: TFileInputType,
  uploadedFileSrc: string,
  setUploadedFile: Dispatch<SetStateAction<FileWithPath | null>>,
  setUploadedFileSrc: Dispatch<SetStateAction<string>>,
  setFormValue: any,
  uploadedFile: any
) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [croppedImage, setCroppedImage] = useState<Blob | MediaSource | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const zoomStep = 0.3;
  const [audioDuration, setAudioDuration] = useState(0);
  const { setSecondsAndUpdate, formattedTime } = useSecondsConverter(audioDuration);
  const [uploadedPremiumFIleType, setUploadedPremiumFIleType] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (uploadedFile) {
      setUploadedPremiumFIleType(getFileType(uploadedFile as File));
    }
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      if (croppedImage) {
        fetch(croppedImage as any)
          .then((response) => response.blob())
          .then((blobData) => {
            setUploadedFile(blobData as any);
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    }, 500);

    return () => {
      id && clearTimeout(id);
    };
  }, [croppedImage]);

  useEffect(() => {
    if ((type === 'audio' || uploadedPremiumFIleType === 'audio') && uploadedFileSrc) {
      fetch(uploadedFileSrc as any)
        .then((response) => response.blob())
        .then((blobData) => {
          getBlobDuration(blobData).then(function (duration) {
            setAudioDuration(duration);
            setSecondsAndUpdate(duration);
          });
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [type, uploadedPremiumFIleType, uploadedFileSrc]);

  const handleRemoveImg = () => {
    setCroppedImage(null);
    setCroppedAreaPixels({ x: 0, y: 0, width: 0, height: 0 });
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setUploadedFileSrc('');
    setUploadedFile(null);
    setFormValue && setFormValue(EFormNames.picture, '');
    setUploadedPremiumFIleType('');
  };
  const handleRemoveAudio = () => {
    setUploadedFileSrc('');
    setUploadedFile(null);
    setFormValue && setFormValue(EFormNames.voice, '');
    setUploadedPremiumFIleType('');
  };
  const handleRemoveVideo = () => {
    setUploadedFileSrc('');
    setUploadedFile(null);
    setUploadedPremiumFIleType('');
  };

  async function getCroppedImg(
    imageSrc: string,
    pixelCrop: { x: number; y: number; width: number; height: number },
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image: HTMLImageElement = (await createImage(imageSrc)) as HTMLImageElement;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');

    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
      return null;
    }

    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      croppedCanvas.toBlob((file: Blob | MediaSource | null) => {
        if (file) {
          resolve(URL.createObjectURL(file));
        }
      }, 'image/jpeg');
    });
  }

  const showCroppedImage = async () => {
    try {
      if (uploadedFileSrc && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(uploadedFileSrc, croppedAreaPixels);
        setCroppedImage(croppedImage as any);
      } else {
        console.error('Missing croppedAreaPixels or imageSrc');
      }
    } catch (e) {
      console.error('Error while cropping:', e);
    }
  };

  const getAccept = (type: TUploadedFileType) => {
    return { [FILE_INPUT_TYPES[type].accept]: FILE_INPUT_TYPES[type].acceptValues };
  };

  const onDrop = (acceptedFile: File[]) => {
    if (!acceptedFile?.length) {
      enqueueSnackbar('Incorrect file format', { variant: 'error' });
      return;
    } else {
      setUploadedPremiumFIleType(getFileType(acceptedFile[0]));
    }
    try {
      setUploadedFile(acceptedFile[0]);
      setUploadedFileSrc(URL.createObjectURL(acceptedFile[0]));
      if (acceptedFile[0].type?.includes('audio')) {
        getBlobDuration(acceptedFile[0]).then(function (duration) {
          setAudioDuration(duration);
          setSecondsAndUpdate(duration);
        });
      }
    } catch {
      console.log(`The maximum file size is ${FILE_INPUT_TYPES[type].sizeLimit / 1000000}MB`);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: getAccept(type),
    onDrop,
    maxSize: FILE_INPUT_TYPES[type].sizeLimit,
  });
  const onCropComplete = useCallback(
    (
      croppedArea: { x: number; y: number; width: number; height: number },
      croppedAreaPixels: { x: number; y: number; width: number; height: number }
    ) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const createImage = (url: string) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });
  };

  const handleZoom = (type: 'in' | 'out') => {
    if (type === 'in') {
      if (zoom + zoomStep <= 4) {
        setZoom((prev) => prev + zoomStep);
      } else {
        setZoom(4);
      }
    } else if (type === 'out') {
      if (zoom - zoomStep >= 1) {
        setZoom((prev) => prev - zoomStep);
      } else {
        setZoom(1);
      }
    }
  };

  function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
  }

  function rotateSize(width: number, height: number, rotation: number) {
    const rotRad = getRadianAngle(rotation);

    return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
  }
  const handleCropChange = (e: { x: number; y: number }) => {
    setCrop({ x: e.x, y: e.y });
    showCroppedImage();
  };

  return {
    getRootProps,
    getInputProps,
    croppedImage,
    crop,
    zoom,
    onCropComplete,
    formattedTime,
    audioDuration,
    uploadedPremiumFIleType,
    handleCropChange,
    setZoom,
    handleZoom,
    handleRemoveImg,
    handleRemoveAudio,
    handleRemoveVideo,
  };
};

import { useState } from 'react';

import { EHeader } from '../types/header';

export const useHeader = (initialHeader?: EHeader) => {
  const [headerType, setHeaderType] = useState<EHeader | undefined>(initialHeader);

  const handleHeaderType = (val: EHeader) => {
    setHeaderType(val);
  };
  return {
    headerType,
    handleHeaderType,
  };
};

import { memo } from 'react';

import { RemoveIcon } from '../../assets/icons/RemoveIcon';
import styles from './styles.module.scss';

interface IVideoProps {
  src: string;
  handleRemoveVideo: () => void;
}

const Video = ({ src, handleRemoveVideo }: IVideoProps) => {
  return (
    <div className={styles.cropper}>
      <div
        className={styles.removeIcon}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleRemoveVideo();
        }}
      >
        <RemoveIcon />
      </div>
      <video width="550" height="550" controls>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export default memo(Video);

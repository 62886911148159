import React, { useContext, useState } from 'react';

import $api from '../../../../api/intercepter';
import { LetterIcon } from '../../../../assets/icons/LetterIcon';
import Button from '../../../../components/Button';
import ContentTitleBlock from '../../../../components/ContentTitleBlock';
import TextButton from '../../../../components/TextButton';
import { MainContext } from '../../../../povider/MainProvider';
import styles from './styles.module.scss';

const SendModal: React.FC = () => {
  const { setModalType } = useContext(MainContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);

    const id = await sessionStorage.getItem('sendId');
    $api
      .post('/premiumcontent/send', { id: Number(id) })
      .then(() => {
        window.history.replaceState(null, '', '/premium?isSent');
        setModalType('');
        sessionStorage.removeItem('sendId');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className={styles.modalWrapper}>
      <ContentTitleBlock
        icon={<LetterIcon />}
        title={'Sending content'}
        subTitle={
          "You're about to send out your content to all of your Telegram audience. Where they’ll have the option to purchase and engage with your premium content. "
        }
        subTitleMaxWidth={616}
        titleMaxWidth={519}
      />
      <div className={styles.btnBlock}>
        <Button
          text={'Confirm'}
          onClick={handleConfirm}
          className={styles.mainBtn}
          isLoading={isLoading}
        />
        <TextButton
          text={'Cancel'}
          onClick={() => {
            setModalType('');
          }}
          theme={'Negative'}
          isDisable={isLoading}
        />
      </div>
    </div>
  );
};

export default React.memo(SendModal);

import React, { useContext, useEffect, useMemo } from 'react';
import ReactModal from 'react-modal';

import { MainContext } from '../../povider/MainProvider';
import { MODALS, TModal } from './constants';
import styles from './modal.module.scss';

ReactModal.setAppElement('#root');

const AppModal = () => {
  const { modalType } = useContext(MainContext);

  useEffect(() => {
    const APP: HTMLBodyElement | null = document?.querySelector('body');
    if (APP) {
      if (modalType) {
        APP.style.overflow = 'hidden';
        APP.style.backgroundColor = 'rgba(0, 0, 0, 0.40)';
      } else {
        APP.removeAttribute('style');
      }
    }
  }, [modalType]);
  const Component = useMemo(() => {
    return MODALS[modalType as TModal];
  }, [modalType]);
  return (
    <ReactModal
      overlayClassName={styles.overlay}
      portalClassName="modal_react"
      isOpen={!!modalType}
      className={styles.main}
      contentLabel="Example Modal"
    >
      {modalType && <>{Component}</>}
    </ReactModal>
  );
};

export default AppModal;

export const Plus: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 12C0 11.6412 0.126061 11.3358 0.378182 11.0836C0.64 10.8218 0.945455 10.6909 1.29455 10.6909H10.7055V1.29455C10.7055 0.945455 10.8315 0.644848 11.0836 0.392727C11.3358 0.130909 11.6412 0 12 0C12.3588 0 12.6642 0.130909 12.9164 0.392727C13.1782 0.644848 13.3091 0.945455 13.3091 1.29455V10.6909H22.7055C23.0545 10.6909 23.3552 10.8218 23.6073 11.0836C23.8691 11.3358 24 11.6412 24 12C24 12.3588 23.8691 12.6642 23.6073 12.9164C23.3552 13.1685 23.0545 13.2945 22.7055 13.2945H13.3091V22.7055C13.3091 23.0545 13.1782 23.3552 12.9164 23.6073C12.6642 23.8691 12.3588 24 12 24C11.6412 24 11.3358 23.8691 11.0836 23.6073C10.8315 23.3552 10.7055 23.0545 10.7055 22.7055V13.2945H1.29455C0.945455 13.2945 0.64 13.1685 0.378182 12.9164C0.126061 12.6642 0 12.3588 0 12Z"
        fill="black"
      />
    </svg>
  );
};

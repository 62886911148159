import React, { useContext, useState } from 'react';

import { GirlHeadIcon } from '../../assets/icons/GirlHeadIcon';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import Input from '../../components/Input';
import LoaderPage from '../../components/LoaderPage.tsx';
import TextButton from '../../components/TextButton';
import MainFrame from '../../features/MainFrame';
import { useAuth } from '../../hooks/useAuth';
import { MainContext } from '../../povider/MainProvider';
import { EHeader } from '../../types/header';
import ChangePassword from './ChangePassword';
import styles from './styles.module.scss';

interface IProps {
  handleHeaderType?: (arg?: EHeader) => void;
}
const Profile: React.FC<IProps> = ({ handleHeaderType }) => {
  const [isChangePassword, setIsChangePassword] = useState(false);
  const { userData } = useContext(MainContext);
  const { isLoading } = useAuth();

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <MainFrame headerType={EHeader.advanced}>
      {isChangePassword ? (
        <ChangePassword setIsChangePassword={setIsChangePassword} />
      ) : (
        <>
          <ContentTitleBlock
            icon={<GirlHeadIcon />}
            title={'My profile'}
            subTitle={
              "View and update essential information. Your email and Telegram username are your key identifiers within the platform. If you'd like to change either of them, please contact our support team for assistance."
            }
            subTitleMaxWidth={711}
          />
          <div className={styles.profileContent}>
            <Input name={'email'} readOnly value={userData?.email} />
            <Input name={'full_name'} readOnly value={userData?.full_name} />
            <TextButton text={'Change password'} onClick={() => setIsChangePassword(true)} />
          </div>
        </>
      )}
    </MainFrame>
  );
};

export default React.memo(Profile);

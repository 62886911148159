import React from 'react';

import { LikeIcon } from '../../assets/icons/LikeIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import { EHeader } from '../../types/header';

interface IProps {
  handleHeaderType?: (arg?: EHeader) => void;
  handleBack: (arg: boolean) => void;
}
const SupportSuccess: React.FC<IProps> = ({ handleBack, handleHeaderType }) => {
  return (
    <>
      <ContentTitleBlock
        icon={<LikeIcon />}
        title={'Thank you'}
        subTitle={
          "We appreciate your interest and value the time you've taken to connect with us. Our team will review your enquiry and you can expect a response from us shortly."
        }
        subTitleMaxWidth={749}
      />
      <Button
        text={'Done'}
        onClick={() => {
          if (handleHeaderType) {
            handleHeaderType(EHeader.simple);
          }
          handleBack(false);
        }}
      />
    </>
  );
};

export default React.memo(SupportSuccess);

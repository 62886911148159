/* eslint-disable react/no-unescaped-entities */
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import $api from '../../api/intercepter';
import { HelloIcon } from '../../assets/icons/HelloIcon';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import LoaderPage from '../../components/LoaderPage.tsx';
import { ROUTES } from '../../constants/routes';
import MainFrame from '../../features/MainFrame';
import { useAuth } from '../../hooks/useAuth';
import { EHeader } from '../../types/header';
import styles from './styles.module.scss';

export interface IReferralData {
  short_code: string;
  click_counter: number;
  subscribers: number;
  total_revenue: string;
}

const Referrals: React.FC = () => {
  //TODO
  const ref: any = useRef(null);
  const { isLoading } = useAuth();
  const [isShowCopyLinkText, setIsShowCopyLinkText] = useState(false);
  const [data, setData] = useState<IReferralData>();

  useEffect(() => {
    $api
      .get('/referral')
      .then((response) => {
        setData(response.data.result[0]);
      })
      .catch(() => {
        console.log('err');
      });
  }, []);
  const handleCopy = () => {
    navigator.clipboard.writeText(ref.current?.innerText);
    setIsShowCopyLinkText(true);

    setTimeout(() => {
      setIsShowCopyLinkText(false);
    }, 2000);
  };
  if (isLoading || !data) {
    return <LoaderPage />;
  }
  return (
    <MainFrame headerType={EHeader.advanced}>
      <div className={styles.container}>
        <ContentTitleBlock
          width={966}
          title={'Share and earn'}
          subTitle={
            "It's time to spread the word and reap the rewards. When you share your unique referral link with your friends and connections, and they successfully gain an approved account, you'll receive a 5% commission from their activity. The more you share, the more you earn. It's that simple."
          }
          icon={<HelloIcon />}
        />
        <div className={styles.options}>
          <div className={clsx([styles.card, styles.mainCard])}>
            <div>${data.total_revenue}</div>
            <div>Total referral revenue</div>
          </div>
          <div className={styles.card}>
            <div>{data.click_counter}</div>
            <div>Referral link clicks</div>
          </div>
          <div className={styles.card}>
            <div>{data.subscribers}</div>
            <div>Referrals approved</div>
          </div>
        </div>
        <div className={styles.refLink}>
          <div ref={ref} className={styles.refValue}>
            {`${window.location.host}${ROUTES['referral-link']}?${data.short_code}`}
          </div>
          <div onClick={handleCopy} className={styles.copyButton}>
            <p style={{ opacity: Number(isShowCopyLinkText) }} className={styles.hiddenText}>
              Link copied to clipboard
            </p>
            Copy link
          </div>
        </div>
      </div>
    </MainFrame>
  );
};

export default React.memo(Referrals);

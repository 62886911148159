import React from 'react';

import { ManIcon } from '../../assets/icons/ManIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import styles from './styles.module.scss';

const AfterEditBankCard: React.FC = () => {
  return (
    <div className={styles.payoutsContainer}>
      <ContentTitleBlock
        icon={<ManIcon />}
        title={'We’re working on your changes'}
        subTitle={
          'Please note that it may take up to 5 days to approve your changes. You’ll be notified once complete'
        }
        subTitleMaxWidth={586}
      />
      <Button
        text={'Back to payouts'}
        onClick={() => window.location.reload()}
        className={styles.viewPayoutBtn}
      />
    </div>
  );
};

export default React.memo(AfterEditBankCard);

import React, { useState } from 'react';

import { InfoIcon } from '../../assets/icons/InfoIcon';
import { MoneyBag } from '../../assets/icons/MoneyBag';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import TextButton from '../../components/TextButton';
import { EHeader } from '../../types/header';
import { IPayoutMethod } from '../../types/user';
import BankCard from './BankCard';
import styles from './styles.module.scss';

interface IProps {
  payoutData: IPayoutMethod;
  handleHeaderType?: (arg?: EHeader) => void;
}

const PayoutView: React.FC<IProps> = ({ payoutData, handleHeaderType }) => {
  const [isShowBankCard, setIsShowBankCard] = useState(false);
  return (
    <div className={styles.payoutsContainer}>
      {isShowBankCard ? (
        <BankCard isEditing payoutData={payoutData} handleHeaderType={handleHeaderType} />
      ) : (
        <>
          <ContentTitleBlock
            icon={<MoneyBag />}
            title={'Seamless payouts'}
            subTitle={
              'We believe in providing you with a seamless and hassle-free earnings experience.'
            }
            subTitleMaxWidth={486}
          />
          <div className={styles.payoutsContent}>
            <div className={styles.totalFounds}>
              <span>$435.06</span>
              <p>
                Your Total Funds
                <div className={styles.infoIconBlock}>
                  <InfoIcon />
                  <p className={styles.hiddenText}>
                    Total funds consist of all your weekly earnings, wether or not they’re available
                    to be paid out to you. This includes your processing and on-hold funds.
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div className={styles.bankAccountCard}>
            <div>
              <p className={styles.endDate}>Bank Account ending in 000</p>
              <p className={styles.updateDate}>Last updated 25th Aug, 2023</p>
            </div>
            <TextButton text={'Edit'} onClick={() => setIsShowBankCard(true)} />
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(PayoutView);

import React from 'react';

import { DevIcon } from '../../assets/icons/DevIcon';
import { PrepareIcon } from '../../assets/icons/PrepareIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import styles from './styles.module.scss';

interface IProps {
  isEditing?: boolean;
}

const FormPrepare: React.FC<IProps> = ({ isEditing }) => {
  return (
    <div className={styles.firstPersonaContent}>
      <ContentTitleBlock
        icon={isEditing ? <DevIcon /> : <PrepareIcon />}
        title={isEditing ? 'We’re working on your changes' : 'Your Persona is being prepared'}
        subTitle={
          isEditing
            ? "Please note that it may take up to 10 days for these changes to fully take effect. So, don't be alarmed if you don't see immediate results."
            : "We're currently processing your Personas activation, this may take up to 10 days to complete. Once your Persona is activated, you'll be notified and ready to engage with your audience like never before."
        }
        subTitleMaxWidth={713}
      />
      <Button
        text={isEditing ? 'Done' : 'Go to dashboard'}
        onClick={() => window.location.reload()}
        theme={'Primary'}
        className={styles.prepareBtn}
      />
    </div>
  );
};

export default React.memo(FormPrepare);

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import * as Yup from 'yup';

import $api from '../../api/intercepter';
import { ForgotPassIcon } from '../../assets/icons/ForgotPassIcon';
import { KeyIcon } from '../../assets/icons/KeyIcon';
import { LikeIcon } from '../../assets/icons/LikeIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import Input from '../../components/Input';
import Loader from '../../components/Loader';
import TextButton from '../../components/TextButton';
import { ROUTES } from '../../constants/routes';
import MainFrame from '../../features/MainFrame';
import { EHeader } from '../../types/header';
import { errorHandler } from '../../utils/errorHandler';
import styles from './styles.module.scss';

const INITIAL_VALUES = {
  new_password: '',
  confirm_password: '',
};

const schema = yup.object().shape({
  new_password: yup.string().required('Required').min(10, 'Password must be min 10 characters'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), ''], 'Passwords must match')
    .required('Required'),
});
const ForgotPassword: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);

  const { search } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getIsValidToken = async () => {
    setIsRequestLoading(true);
    await $api
      .post('/password/validate', {
        token: search.split('=')[1],
      })
      .then((response) => {
        setIsValidToken(response.data.result[0]);
      })
      .catch((error) => {
        errorHandler(error, enqueueSnackbar);
      })
      .finally(() => {
        setIsRequestLoading(false);
      });
  };

  useEffect(() => {
    if (search.includes('token=')) {
      localStorage.removeItem('token');
      getIsValidToken();
    }
  }, []);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: schema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      setIsLoading(true);
      getIsValidToken().then(() => {
        $api
          .post('/password/reset', {
            token: search.split('=')[1],
            new_password: values.new_password,
          })
          .then((response) => {
            if (response.data.result[0]) {
              setIsSuccess(true);
            } else {
              enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
          })
          .catch((error) => {
            errorHandler(error, enqueueSnackbar, formik);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    },
  });
  if (isRequestLoading) {
    return (
      <MainFrame headerType={EHeader.simple}>
        <Loader />
      </MainFrame>
    );
  }

  return (
    <MainFrame headerType={EHeader.simple}>
      {isSuccess ? (
        <>
          <ContentTitleBlock
            icon={<LikeIcon />}
            title={'Your all set'}
            subTitle={
              'Your password has been set successfully. Your account security is our priority, please sign in with your new password'
            }
            subTitleMaxWidth={639}
          />
          <TextButton
            text={'Back to Sign in'}
            onClick={() => {
              navigate(ROUTES.signIn);
            }}
            theme={'Secondary'}
            className={styles.textBtn}
          />
        </>
      ) : (
        <div className={styles.resetContainer}>
          <ContentTitleBlock
            icon={isValidToken ? <ForgotPassIcon /> : <KeyIcon />}
            title={isValidToken ? 'Set your password' : 'Your link has expired.'}
            subTitle={
              isValidToken
                ? "Reset your password and regain access to your account. We're here to help you keep your account secure"
                : 'Please make a new request in order to change your password.'
            }
            subTitleMaxWidth={isSuccess ? 637 : 545}
          />
          {isValidToken ? (
            <div className={styles.resetForm}>
              <Input
                name={'new_password'}
                placeholder={'New password'}
                type={'password'}
                className={styles.passInput}
                value={formik.values.new_password}
                error={formik.errors.new_password as string}
                handleChange={formik.setFieldValue}
                onKeyDown={formik.handleSubmit}
              />
              <Input
                name={'confirm_password'}
                placeholder={'Confirm new password'}
                type={'password'}
                className={styles.passInput}
                value={formik.values.confirm_password}
                error={formik.errors.confirm_password as string}
                handleChange={formik.setFieldValue}
                onKeyDown={formik.handleSubmit}
              />
              <Button
                text={'Set Password'}
                onClick={formik.handleSubmit}
                className={styles.mainBtn}
                isLoading={isLoading}
              />
            </div>
          ) : (
            <TextButton
              text={'Request new password change'}
              onClick={() => navigate(ROUTES.forgotPassword)}
              theme={'Secondary'}
            />
          )}
        </div>
      )}
    </MainFrame>
  );
};

export default React.memo(ForgotPassword);

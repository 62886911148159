import React from 'react';

import { CameraIcon } from '../../assets/icons/CameraIcon';
import { GirlIcon } from '../../assets/icons/GirlIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import FileInput from '../../components/FileInput';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import TextButton from '../../components/TextButton';
import { usePremium } from '../../hooks/usePremium';
import { getFileType } from '../../utils/getFileType';
import { EPremiumFormNames } from './constants';
import ContentSummary from './ContentSummary';
import styles from './styles.module.scss';

interface IProps {
  handleBack: () => void;
}

const UploadContent: React.FC<IProps> = ({ handleBack }) => {
  const {
    isShowSummary,
    isLoading,
    isForm,
    formik,
    uploadedFileSrc,
    uploadedFile,
    isUploaded,
    teaserFile,
    teaserFileFileSrc,
    setTeaserFileSrc,
    setTeaserFile,
    setUploadedFileSrc,
    setUploadedFile,
    handleBtnClick,
    setIsShowSummary,
    setIsForm,
  } = usePremium();

  if (isShowSummary && uploadedFile) {
    return (
      <ContentSummary
        isUploaded={isUploaded}
        data={formik.values}
        setIsSummary={setIsShowSummary}
        formik={formik}
        isLoading={isLoading}
        fileUrl={uploadedFileSrc}
        teaserFileUrl={teaserFileFileSrc}
        fileType={getFileType(uploadedFile)}
      />
    );
  }
  return (
    <div className={styles.premiumContainer}>
      <ContentTitleBlock
        icon={isForm ? <GirlIcon /> : <CameraIcon />}
        title={isForm ? 'Set your price' : 'Upload Content'}
        subTitle={
          isForm
            ? 'Ready to share your premium content with your eager audience? Set the price, add relevant tags, and craft an engaging message'
            : 'Share moments and captivate your audience by uploading images or videos that will leave them eager to purchase'
        }
        subTitleMaxWidth={810}
      />
      <div className={styles.uploadContent}>
        {isForm ? (
          <div className={styles.form}>
            <Input
              value={formik.values[EPremiumFormNames.title]}
              handleChange={formik.setFieldValue}
              error={formik.errors[EPremiumFormNames.title]}
              name={EPremiumFormNames.title}
              placeholder={'Content title'}
            />
            <Input
              value={formik.values[EPremiumFormNames.price]}
              handleChange={formik.setFieldValue}
              error={formik.errors[EPremiumFormNames.price]}
              name={EPremiumFormNames.price}
              placeholder={'Price'}
              postFix={'USD'}
              type={'number'}
            />
            <TextArea
              value={formik.values[EPremiumFormNames.tags]}
              name={EPremiumFormNames.tags}
              placeholder={'#Tags'}
              height={94}
              handleChange={formik.setFieldValue}
              error={formik.errors[EPremiumFormNames.tags]}
            />
            <TextArea
              value={formik.values[EPremiumFormNames.message]}
              handleChange={formik.setFieldValue}
              error={formik.errors[EPremiumFormNames.message]}
              name={EPremiumFormNames.message}
              placeholder={'Content message'}
              height={148}
            />
          </div>
        ) : (
          <div className={styles.uploadedFilesBlock}>
            <FileInput
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              uploadedFileSrc={uploadedFileSrc}
              setUploadedFileSrc={setUploadedFileSrc}
              type={'premium'}
              title={'Main content'}
            />
            <FileInput
              uploadedFile={teaserFile}
              setUploadedFile={setTeaserFile}
              uploadedFileSrc={teaserFileFileSrc}
              setUploadedFileSrc={setTeaserFileSrc}
              type={uploadedFile ? getFileType(uploadedFile) : 'premium'}
              title={'Teaser (Optional)'}
              isDisabled={!(uploadedFile || teaserFile)}
              isTeaser
            />
          </div>
        )}
        <div className={styles.btnBlock}>
          <Button
            text={'Continue'}
            onClick={handleBtnClick}
            theme={'Primary'}
            className={styles.mainBtn}
            isDisable={!uploadedFile}
          />
          <TextButton
            text={'Back'}
            onClick={() => {
              if (isForm) {
                setIsForm(false);
              } else {
                handleBack();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(UploadContent);

import React, { createContext, ReactNode, useState } from 'react';

import { TModal } from '../features/AppModal/constants';
import { IUserData } from '../types/user';

interface MainContextInterface {
  /* TODO*/
  isAuthenticated?: boolean; // TODO
  modalType: string; // TODO
  userData: IUserData | null; // TODO
  setModalType: (val: TModal | '') => void; // TODO
  setIsAuthenticated: (val: boolean) => void; // TODO
  setUserData: (val: any) => void; // TODO
}

export const MainContext = createContext<MainContextInterface>({
  modalType: '',
  userData: null,
  setModalType: () => console.log(''),
  setIsAuthenticated: () => console.log(''),
  setUserData: () => console.log(''),
});
interface IMainProvider {
  children: ReactNode;
}

const MainProvider: React.FC<IMainProvider> = ({ children }) => {
  const [modalType, setModalType] = useState<TModal | ''>('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState<IUserData | null>(null);
  return (
    <MainContext.Provider
      value={{
        isAuthenticated,
        userData,
        setUserData,
        setIsAuthenticated,
        modalType,
        setModalType,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainProvider;

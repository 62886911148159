export const Minus: React.FC = () => {
  return (
    <svg width="24" height="3" viewBox="0 0 24 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.29455 3C1.06182 3 0.848485 2.93296 0.654545 2.79888C0.460606 2.6648 0.300606 2.48603 0.174545 2.26257C0.0581818 2.02793 0 1.77095 0 1.49162C0 1.22346 0.0581818 0.977654 0.174545 0.75419C0.300606 0.519553 0.460606 0.335196 0.654545 0.201117C0.848485 0.0670391 1.06182 0 1.29455 0H22.7055C22.9382 0 23.1515 0.0670391 23.3455 0.201117C23.5491 0.335196 23.7091 0.519553 23.8255 0.75419C23.9418 0.977654 24 1.22346 24 1.49162C24 1.77095 23.9418 2.02793 23.8255 2.26257C23.7091 2.48603 23.5491 2.6648 23.3455 2.79888C23.1515 2.93296 22.9382 3 22.7055 3H1.29455Z"
        fill="black"
      />
    </svg>
  );
};

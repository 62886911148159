import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import $api from '../../api/intercepter';
import LoaderPage from '../../components/LoaderPage.tsx';
import MainFrame from '../../features/MainFrame';
import { useAuth } from '../../hooks/useAuth';
import { errorHandler } from '../../utils/errorHandler';
import AboutPart from './components/AboutPart';
import EmailPart from './components/EmailPart';
import SuccessPart from './components/SuccessPart';
import { EUserKey, INITIAL_VALUES, schema } from './constants';

const Home: React.FC = () => {
  const [isEmailPart, setIsEmailPart] = useState(true);
  const [isSubmitLoading, setIsSubMitLoading] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [isAccountPlane, setIsAccountPlane] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, referralCode } = useAuth(true);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: schema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      setIsSubMitLoading(true);
      const dateOfBirth = values[EUserKey.date_of_birth].split(' ').join('-');

      $api
        .post('/register', {
          email: values[EUserKey.email],
          full_name: values[EUserKey.full_name],
          date_of_birth: dateOfBirth,
          onlyfans_url: values[EUserKey.onlyfans_url],
          instagram_url: values[EUserKey.instagram_url],
          tiktok_url: values[EUserKey.tiktok_url],
          youtube_url: values[EUserKey.youtube_url],
          snapchat_url: values[EUserKey.snapchat_url],
          twitch_url: values[EUserKey.twitch_url],
          account_type: values[EUserKey.account_type],
          ...(referralCode ? { short_code: referralCode } : {}),
        })
        .then(() => {
          setIsRequestSuccess(true);
        })
        .catch((error) => {
          setIsAccountPlane(false);
          errorHandler(error, enqueueSnackbar, formik);
        })
        .finally(() => {
          setIsSubMitLoading(false);
        });
    },
  });
  if (isLoading) {
    return <LoaderPage />;
  }
  return (
    <MainFrame>
      {isRequestSuccess ? (
        <SuccessPart />
      ) : isEmailPart ? (
        <EmailPart formik={formik} setIsEmailPart={setIsEmailPart} />
      ) : (
        <AboutPart
          isSubmitLoading={isSubmitLoading}
          formik={formik}
          setIsEmailPart={setIsEmailPart}
          isAccountPlane={isAccountPlane}
          setIsAccountPlane={setIsAccountPlane}
        />
      )}
    </MainFrame>
  );
};

export default React.memo(Home);

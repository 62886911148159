import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';

interface IProps {
  value: string;
  name: string;
  placeholder?: string;
  height?: number;
  mainLabel?: string;
  smallLabel?: string;
  error?: string | boolean;

  handleChange: (_name: string, _value: string) => void;
  onFocus?: (_name: string, _isTouched?: boolean) => void;
}

const TextButton: React.FC<IProps> = ({
  value,
  mainLabel,
  smallLabel,
  placeholder,
  error,
  name,
  handleChange,
  onFocus,
  height = 121,
}) => {
  const onChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = evt.target;
    handleChange(name, value);
  };
  const handleFocus = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name } = evt.target;
    if (onFocus) {
      onFocus(name);
    }
  };
  return (
    <div className={styles.container}>
      {!!mainLabel && <p className={styles.mainLabel}>{mainLabel}</p>}
      {!!smallLabel && <p className={styles.smallLabel}>{smallLabel}</p>}
      <textarea
        style={{ height }}
        name={name}
        placeholder={placeholder}
        className={clsx({
          [styles.error]: !!error,
        })}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
      />
      <p className={styles.errorMessage}>{error}</p>
    </div>
  );
};

export default TextButton;

import React, { useContext } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { ROUTES } from '../constants/routes';
import Auth from '../pages/auth';
import ForgotPassword from '../pages/forgotPassword';
import Home from '../pages/home';
import Payouts from '../pages/payouts';
import Persona from '../pages/persona';
import Premium from '../pages/premium';
import Profile from '../pages/profile';
import Referrals from '../pages/referals';
import Reset from '../pages/reset';
import Support from '../pages/support';
import { MainContext } from '../povider/MainProvider';

const Routing = () => {
  const { isAuthenticated } = useContext(MainContext);
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.home} element={<Home />} />
        <Route path={ROUTES.signIn} element={<Auth />} />
        <Route path={ROUTES.reset} element={<Reset />} />
        <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} />

        {/*Private Routes*/}
        <Route path={ROUTES.payouts} element={<Payouts />} />
        <Route path={ROUTES.persona} element={<Persona />} />
        <Route path={ROUTES.premium} element={<Premium />} />
        <Route path={ROUTES.referrals} element={<Referrals />} />
        <Route path={ROUTES.support} element={<Support />} />
        <Route path={ROUTES.profile} element={<Profile />} />
        <Route path={ROUTES['referral-link']} element={<Home />} />

        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default Routing;

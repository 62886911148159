import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { SelectIcon } from '../../assets/icons/SelectIcon';
import useOutsideClick from '../../hooks/useOutsideClick';
import Input from '../Input';
import styles from './styles.module.scss';
import { TSelectItem, TSelectOptions } from './type';

interface IProps {
  name: string;
  placeholder?: string;
  selectedItem?: TSelectItem;
  options: TSelectOptions;
  handleChange: (_name: string, _value: string) => void;
  className?: any;
  width?: number;
  error?: string;
}

const Select: React.FC<IProps> = ({
  handleChange,
  selectedItem,
  options,
  name,
  placeholder,
  className,
  width,
  error,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(selectedItem?.label || '');
  const [isOpen, setIsOpen] = useState(false);
  const optionsRef = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    if (selectedItem) {
      setSelectedValue(selectedItem.label);
    }
  }, [selectedItem]);

  useOutsideClick([optionsRef, inputRef], () => {
    setIsOpen(false);
  });

  const optionsData = useMemo(() => {
    if (selectedItem) {
      options.filter((item) => {
        return item.value !== selectedItem.value;
      });
    }
    return options;
  }, [selectedValue, options]);
  const handleClick = (item: TSelectItem) => {
    setSelectedValue(item.label);
    handleChange(name, item.value);
    setIsOpen(false);
  };
  return (
    <div
      className={clsx(styles.container, { [className]: className })}
      style={width ? { width: `${width}px` } : {}}
    >
      <div
        ref={inputRef}
        className={styles.selectInputContainer}
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <Input
          name={'selectInput'}
          value={selectedValue}
          placeholder={placeholder}
          error={error}
          readOnly
        />
        <span className={clsx(styles.selectIcon, { [styles.rotated]: isOpen })}>
          <SelectIcon />
        </span>
      </div>
      {isOpen && (
        <div className={styles.optionsContainer} ref={optionsRef}>
          {optionsData.map((item) => {
            return (
              <div onClick={() => handleClick(item)} key={item.value} className={styles.option}>
                {item.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Select;

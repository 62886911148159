import React, { useState } from 'react';

import { LikeIcon } from '../../assets/icons/LikeIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import PayoutView from './PayoutView';
import styles from './styles.module.scss';

const ReadyPayout: React.FC = () => {
  return (
    <div className={styles.payoutsContainer}>
      <ContentTitleBlock
        icon={<LikeIcon />}
        title={'Your ready to receive payouts'}
        subTitle={
          'Your new payout method has been successfully added to your account. This means hassle-free payouts.'
        }
        subTitleMaxWidth={549}
      />
      <Button
        text={'View payouts'}
        onClick={() => window.location.reload()}
        className={styles.viewPayoutBtn}
      />
    </div>
  );
};

export default React.memo(ReadyPayout);

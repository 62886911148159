import React, { useState } from 'react';

import { LikeIcon } from '../../assets/icons/LikeIcon';
import Button from '../../components/Button';
import ContentTitleBlock from '../../components/ContentTitleBlock';
import TextButton from '../../components/TextButton';
import styles from './styles.module.scss';
import UploadContent from './UploadContent';

const ReadyAndWaiting: React.FC = () => {
  const [isUploadMore, setIsUploadMore] = useState(false);
  if (isUploadMore) {
    return <UploadContent handleBack={() => setIsUploadMore(false)} />;
  }

  return (
    <div className={styles.premiumContainer}>
      <ContentTitleBlock
        icon={<LikeIcon />}
        title={'Ready and waiting'}
        subTitle={
          "Your premium content has been uploaded and with just a few clicks, you're ready to send out this captivating content to your eager audience"
        }
        subTitleMaxWidth={749}
      />
      <div className={styles.btnBlock}>
        <Button
          text={'Upload more'}
          onClick={() => setIsUploadMore(true)}
          theme={'Primary'}
          className={styles.mainBtn}
        />
        <TextButton
          text={'Go to premium'}
          onClick={() => {
            window.location.reload();
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(ReadyAndWaiting);

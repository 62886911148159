import { Dispatch, memo, SetStateAction, useContext } from 'react';
import Cropper from 'react-easy-crop';

import { Minus } from '../../assets/icons/Minus';
import { Plus } from '../../assets/icons/Plus';
import { RemoveIcon } from '../../assets/icons/RemoveIcon';
import { MainContext } from '../../povider/MainProvider';
import styles from './styles.module.scss';

interface ICroppedImageProps {
  src: string;
  crop: { x: number; y: number };
  zoom: number;
  onCropComplete: any;
  setZoom: Dispatch<SetStateAction<number>>;
  handleZoom: (type: 'in' | 'out') => void;
  croppedImage: Blob | MediaSource | null;
  onCropChange: (e: { x: number; y: number }) => void;
  handleRemoveImg: () => void;
  isShowZoomControl: boolean;
}
const CroppedImage = ({
  src,
  crop,
  zoom,
  onCropComplete,
  setZoom,
  handleZoom,
  onCropChange,
  handleRemoveImg,
  isShowZoomControl,
}: ICroppedImageProps) => {
  const { userData } = useContext(MainContext);

  return (
    <div className={styles.main}>
      <div className={styles.cropperWrapper}>
        <Cropper
          image={src}
          crop={crop}
          zoom={zoom}
          onCropComplete={onCropComplete}
          onCropChange={onCropChange}
          onZoomChange={setZoom}
          showGrid={false}
          maxZoom={4}
          minZoom={1}
          objectFit={'cover'}
          classes={{ cropAreaClassName: styles.cropper }}
          cropSize={{ width: 550, height: 550 }}
        />
      </div>
      {!!src && (
        <div
          className={styles.removeIcon}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleRemoveImg();
          }}
        >
          <RemoveIcon />
        </div>
      )}
      {isShowZoomControl && (
        <div className={styles.zoomControls}>
          {userData?.persona?.picture_file_url !== src && (
            <>
              <button
                type="button"
                value={zoom}
                onClick={() => handleZoom('in')}
                className={styles.zoomBtn}
              >
                <Plus />
              </button>
              <button
                type="button"
                value={zoom}
                onClick={() => handleZoom('out')}
                className={styles.zoomBtn}
              >
                <Minus />
              </button>
            </>
          )}
        </div>
      )}{' '}
      )
    </div>
  );
};

export default memo(CroppedImage);

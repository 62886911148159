export const DollarIcon = () => {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.474 13.578C11.474 14.3407 11.276 15.0593 10.88 15.734C10.4987 16.394 9.92667 16.944 9.164 17.384C8.416 17.8093 7.52867 18.0587 6.502 18.132V19.914H5.094V18.11C3.62733 17.978 2.44667 17.538 1.552 16.79C0.657333 16.0273 0.195333 15.0007 0.166 13.71H3.466C3.554 14.766 4.09667 15.404 5.094 15.624V11.422C4.038 11.158 3.18733 10.894 2.542 10.63C1.89667 10.366 1.33933 9.94067 0.87 9.354C0.400667 8.76733 0.166 7.968 0.166 6.956C0.166 5.68 0.620667 4.63867 1.53 3.832C2.454 3.02533 3.642 2.56333 5.094 2.446V0.663999H6.502V2.446C7.91 2.56333 9.032 2.98867 9.868 3.722C10.7187 4.45533 11.1953 5.46733 11.298 6.758H7.976C7.932 6.33267 7.778 5.966 7.514 5.658C7.26467 5.33533 6.92733 5.108 6.502 4.976V9.134C7.602 9.41267 8.46733 9.684 9.098 9.948C9.74333 10.1973 10.3007 10.6153 10.77 11.202C11.2393 11.774 11.474 12.566 11.474 13.578ZM3.378 6.802C3.378 7.286 3.52467 7.682 3.818 7.99C4.11133 8.28333 4.53667 8.52533 5.094 8.716V4.91C4.566 4.98333 4.148 5.18133 3.84 5.504C3.532 5.82667 3.378 6.25933 3.378 6.802ZM6.502 15.668C7.05933 15.5653 7.492 15.338 7.8 14.986C8.12267 14.634 8.284 14.2087 8.284 13.71C8.284 13.226 8.13 12.8373 7.822 12.544C7.514 12.2507 7.074 12.0087 6.502 11.818V15.668Z"
        fill="black"
      />
    </svg>
  );
};
